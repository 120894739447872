const pt_PT = {
  media_type: {
    1: `Canção`,
    2: `identificação de rádio`,
    3: `entre a música`,
    4: `Comercial`,
    5: `Comercial iniciado`,
    6: `Final comercial`,
  },
  mood: {
    1: "Muito calmo",
    2: "Calma",
    3: "normal",
    4: "Movimentos",
    5: "Muito ocupado",
    6: "Muito calmo e tranquilo",
    7: "Calma e Normal",
    8: "Calma, Normal e Ocupada",
    9: "Normais e Movimentos",
    10: "Todos",
  },
  languages: {
    1: "Espanhol",   
    2: "Inglês",     
    3: "Francês",
    4: "Português",
    5: "Estoniano",
    6: "Alemão",
    7: "Italiano"
  },  
  label1: "Idiomas",
  label2: "Música excluída pelo provedor de serviços, edite a lista de reprodução e exclua-a",
  label3: "Música removida pelo provedor de serviços. Remova desta lista",
  label4: "Reproduzindo",
  label5: "Carregando Aplicação...",
  label6: "Painel de Controle",
  label7: "Usuário (Não usar email)",
  label8: "Senha",
  label9: "Iniciar Sessão",
  label10: "Erro, Por favor, forneça nome de usuário e senha.",
  label11: "Erro, Esta conta não é de administrador.",
  label12: "Erro, Usuário suspenso! Entre em contato com ",
  label13: "Erro, Usuário ou senha incorretos.",
  label14: "Erro, Sua assinatura expirou. Ir para ",
  label15:
    "Erro, Falha ao se conectar ao servidor, verifique sua conexão com a internet.",
  label16: "Painel \nde\n Controle",
  label17: "Usuários",
  label18: "Criar, editar e controlar \n usuários (locais)",
  label19: "Clonar configuração de \n um usuário para outros",
  label20: "Personalizar o logotipo \n e dados do programa",
  label21: "Criar e editar \n grupos de usuários",
  label22: "Baixar \n relatórios de uso",
  label23: "Preferências",
  label24: "Criar e editar preferências \n de um usuário",
  label25: "Criar e editar \n lista de reprodução de usuários",
  label26:
    "Atribuir listas de reprodução ou hibernações para cada \n horário de cada dia",
  label27: "Gerenciar estilos musicais",
  label28: "Gerenciar músicas",
  label29: "Anúncios",
  label30: "Carregar e gerenciar mídias",
  label31: "Criar, editar e configurar relógios",
  label32: "Carregar e gerenciar anúncios",
  label33: "Criar, editar e configurar pautas",
  label34: "Atividade",
  label35: "Criar e editar preferências",
  label36: "Grupos de usuários",
  label37: "Planejador de listas de reprodução",
  label38: "Personalização",
  label39: "Planejador de anúncios",
  label40: "Clonagem",
  label41: "Sair",
  label42: "Carregando Dados...",
  label43: "Leia mais",
  label44: "Leia menos",
  label45:
    'Nesta seção, você pode criar usuários. É necessário ter um usuário para cada local, e cada usuário pode ser usado em apenas um local por vez. Você também pode excluir usuários ou editá-los (alterar nome de usuário, senha, etc).\nCada usuário pode ser atribuído a um ou vários grupos de usuários, facilitando a configuração em massa de usuários. Os grupos de usuários são criados por você mesmo na seção "CRIAR E EDITAR GRUPOS DE USUÁRIOS".\nVocê também pode controlar o que é ouvido em cada local a partir desta seção.\nPara atribuir listas de reprodução ou anúncios a um usuário, você deve ir para a seção correspondente ("LISTAS DE REPRODUÇÃO" ou "ANÚNCIOS")',
  label46: "Usuários contratados",
  label47: "Usuários ativos",
  label48: "Usuários disponíveis",
  label49: "Criar usuário",
  label50: "Digite os usuários ou grupos de usuários a serem pesquisados...",
  label51: "Filtrar por...",
  label52: "Ordenar por...",
  label53: "Nome",
  label54: "E-mail",
  label55: "Grupo",
  label56: "Conectados",
  label57: "Desconectados",
  label58: "Nunca conectados",
  label59:
    "Janeiro,Fevereiro,Março,Abril,Maio,Junho,Julho,Agosto,Setembro,Outubro,Novembro,Dezembro",
  label60: "Jan,Fev,Mar,Abr,Mai,Jun,Jul,Ago,Set,Out,Nov,Dez",
  label61:
    "Domingo,Segunda-feira,Terça-feira,Quarta-feira,Quinta-feira,Sexta-feira,Sábado",
  label62: "Dom,Seg,Ter,Qua,Qui,Sex,Sáb",
  label63: "Do,Lu,Ma,Mi,Ju,Vi,Sa",
  label64: "Conectado",
  label65: "Desconectado",
  label66: "Usuário nunca conectado",
  label67: "Suspenso pelo provedor de serviço",
  label68: "Ativo",
  label69: "Reproduzindo a lista de reprodução",
  label70: "Lista de reprodução reproduzida",
  label71: "Desde",
  label72: "Grupo(s)",
  label73: "Não está atribuído a um grupo",
  label74: "Criar \n usuário",
  label75:
    "Os nomes de usuário devem incluir o símbolo @. \n Se não o incluir, será adicionado automaticamente no início do nome de usuário.",
  label76: "Obrigatório",
  label77: "Confirmar senha",
  label78: "Opcional",
  label79: "Descrição",
  label80: "Salvar alterações",
  label81: "Salvando alterações...",
  label82: "Criar",
  label83: "Criando...",
  label84:
    "Os nomes de usuários devem incluir o símbolo @.\n Se não incluir, será adicionado automaticamente no início do nome de usuário.",
  label85: "Sim",
  label86: "Não",
  label87: "Informações sobre",
  label88: "Usuário",
  label89: "Sem dados no momento",
  label90: "Usuário criado",
  label91: "PIN de bloqueio",
  label92: "Não foi atribuído um PIN",
  label93: "Últimas cinco (5) conexões",
  label94: "Desconectado",
  label95: "Pausado",
  label96: "Hibernando",
  label97:
    'Você pode clonar as configurações de um usuário para outro ou outros usuários, ou para um grupo ou grupos. Escolha o usuário de origem e, em seguida, o(s) usuário(s) de destino, ou o grupo ou grupos de destino. Selecione as configurações a serem clonadas e clique no botão "CLONAR".\n IMPORTANTE: As configurações que forem clonadas substituirão as configurações existentes nos usuários de destino. Por exemplo, se você clonar as listas de reprodução do usuário, todas as listas de reprodução criadas pelo usuário de destino ou pelo grupo de usuários de destino serão excluídas.',
  label98: "Escolher usuário de origem...",
  label99:
    "(Selecione o usuário do qual as configurações selecionadas abaixo serão clonadas)",
  label100: "Selecionar usuários",
  label101: "Selecionar grupos",
  label102: "Todos os usuários",
  label103: "Usuário ou usuários de destino",
  label104: "Grupo ou grupos de destino",
  label105:
    "(Selecione o usuário ou usuários aos quais as configurações selecionadas abaixo serão clonadas)",
  label106:
    "(Selecione o grupo ou grupos aos quais as configurações selecionadas abaixo serão clonadas)",
  label107: "Escolher usuário ou usuários de destino...",
  label108: "Escolher grupo ou grupos de destino...",
  label109: "Configurações a serem clonadas",
  label110: "Administrador de conteúdo",
  label111: "(Estilos do usuário e modificações nas músicas)",
  label112: "Clonar",
  label113: "Clonando...",
  label114: "Personalizar o logo \n e dados do programa",
  label115:
    'Você pode personalizar o aplicativo com seu logotipo e seus dados. O logotipo aparecerá no login e no menu, e seus dados aparecerão na seção "Sobre este aplicativo".',
  label116: "Selecione a imagem",
  label117: "Opcional (Substitui o logo do aplicativo)",
  label118: "Carregar imagem quadrada no formato .jpg ou .png.",
  label119: "Carregar imagem",
  label120: "Restaurar",
  label121: "Dados",
  label122: '(Serão exibidos na seção "Sobre este aplicativo" do programa)',
  label123: "Nome da empresa ou comércio",
  label124: "Site",
  label125: "Texto",
  label126: "Opcional (Por exemplo, telefone ou nome de contato)",
  label127: "Salvar",
  label128: "Salvando...",
  label129: "Excluir",
  label130: "Excluindo...",
  label131: "Logo \n do \n usuário",
  label132: "A personalização foi restaurada com sucesso.",
  label133: "Erro, algo deu errado ao restaurar a personalização.",
  label134: "Deseja restaurar a personalização?",
  label135:
    "A personalização será removida e restaurada para a configuração padrão.",
  label136:
    'Crie os grupos e, em seguida, na seção "CRIAR, EDITAR E CONTROLAR USUÁRIOS (LOCAIS)", atribua usuários ao grupo ou grupos desejados. Um usuário pode pertencer a vários grupos. Os grupos de usuários facilitam a administração de muitos usuários ao atribuir configurações como planos de listas de reprodução, padrões publicitários, etc.',
  label137: "Digite o nome do grupo a ser pesquisado...",
  label138: "Criar grupo",
  label139: "Criar \n grupo",
  label140: "Editar",
  label141:
    "Você pode baixar relatórios de listas de reprodução utilizadas e anúncios publicados nas datas desejadas.",
  label142: "Relatórios de anúncios",
  label143: "Relatórios de listas de reprodução",
  label144:
    "(Serão exibidos apenas relatórios relacionados a anúncios publicitários)",
  label145: "Tipo de relatório",
  label146: "Escolher um tipo de relatório...",
  label147: "Mídia",
  label148: "Escolher um meio...",
  label149: "Relatório Geral (Detalhado)",
  label150: "Relatório Discriminado por Seleção de Meio (Resumo)",
  label151: "Relatório Discriminado por Seleção de Meio (Detalhado)",
  label152: "Data inicial",
  label153: "Obrigatório (de)",
  label154: "Data final",
  label155: "Obrigatório (até)",
  label156:
    "(Serão exibidos apenas relatórios relacionados ao uso de listas de reprodução)",
  label157: "Baixar",
  label158: "Baixando...",
  label159: "Erro, algo deu errado ao gerar o relatório.",
  label160: "O relatório foi gerado com sucesso.",
  label161:
    "Erro, o relatório não foi gerado, não há registros neste intervalo de tempo.",
  label162: "Relatório_de_",
  label163: "_(resumo)",
  label164: "_(detalhado)",
  label165: "Nome de usuário",
  label166: "Nome do meio",
  label167: "Nome do arquivo",
  label168: "Horário de",
  label169: "Horário até",
  label170: "Data de reprodução",
  label171: "Número de reprodução",
  label172: "Intervalo de",
  label173: "Intervalo até",
  label174:
    "Atribuir PIN de bloqueio,\n Remover músicas com linguagem inadequada,\nConfigurar hibernação.",
  label175: "Escolher um usuário...",
  label176:
    'Você pode configurar diferentes listas de reprodução ou hibernações para os diferentes horários de cada dia da semana. Selecione um usuário e clique no símbolo "+" no final de cada dia para fazer suas configurações.',
  label177: "Erro, não tem contas ativas, contacte o fornecedor de serviços.",
  label178:
    'Nesta seção, você pode carregar (enviar) anúncios e excluir ou editar seus anúncios já carregados. Em seguida, em "CRIAR, EDITAR E CONFIGURAR PAUTAS", você pode criar suas pautas publicitárias para que os anúncios toquem nos locais.',
  label179: "Digite o nome do anúncio a ser pesquisado...",
  label180: "Fazer upload",
  label181: "Criar, editar \n e configurar pautas",
  label182:
    "Você pode adicionar pautas publicitárias de seus próprios anúncios (previamente carregados) para que toquem nos locais.",
  label183: "Adicionar pauta",
  label184:
    'Crie os grupos e, em seguida, em "CRIAR, EDITAR E CONTROLAR USUÁRIOS (LOCAIS)", atribua usuários ao grupo ou grupos desejados. Um usuário pode pertencer a vários grupos.',
  label185: "Carregar mídia",
  label186: "Anunciante",
  label187: "Marca ou produto",
  label188: "Usuário (Local)",
  label189: "Grupo de usuários",
  label190: "Prioridade",
  label191: "Quantidade de publicações",
  label192: "Tipo de mídia",
  label193: "Até",
  label194: "Localizações",
  label195: "Versões",
  label196: "Ilimitado",
  label197: "Criar, editar \n e configurar relógios",
  label198:
    "Você pode adicionar pautas publicitárias de seus próprios anúncios (previamente carregados).",
  label199: "Relógios",
  label200: "Calendário",
  label201: "Gerenciar relógios",
  label202: "Atribuir relógios para dias normais",
  label203: "Atribuir relógios para dias especiais",
  label204: "Calendário ativado",
  label205: "Calendário desativado",
  label206: "Usuário excluído com sucesso.",
  label207: "Erro, algo deu errado ao excluir o usuário.",
  label208: "Deseja excluir o usuário",
  label209: "O usuário será excluído",
  label210: "Erro, o nome de usuário contém espaços em branco (não permitido).",
  label211: "Erro, o usuário deve ter de 6 a 40 caracteres.",
  label212: "Erro, a senha deve ter de 6 a 40 caracteres.",
  label213: "O usuário foi criado com sucesso.",
  label214: "Erro, a senha e sua confirmação não são iguais.",
  label215: "Erro, a senha é obrigatória.",
  label216: "Erro, o nome de usuário é obrigatório.",
  label217: "Editar usuário",
  label218: "Erro, o nome de usuário contém o caractere # (não permitido).",
  label219: "O usuário foi editado com sucesso.",
  label220: "Erro, algo deu errado ao editar o usuário.",
  label221: "A clonagem foi realizada com sucesso.",
  label222: "Erro, algo deu errado ao realizar a clonagem.",
  label223: "Usuário de origem",
  label224: "Erro, algo deu errado ao salvar o grupo.",
  label225: "O grupo foi salvo com sucesso.",
  label226: "Erro, algo deu errado ao editar o grupo.",
  label227: "O grupo foi editado com sucesso.",
  label228: "O grupo",
  label229: "foi excluído com sucesso.",
  label230: "Erro, algo deu errado ao excluir o grupo.",
  label231: "Deseja excluir o grupo",
  label232: "O grupo será excluído",
  label233: "A personalização foi salva com sucesso.",
  label234: "Erro, algo deu errado ao salvar a personalização.",
  label235: "Mostrar favoritos",
  label236: "Mostra ou oculta a barra de favoritos (barra inferior)",
  label237: "Adicionar PIN de bloqueio",
  label238: "Alterar PIN de bloqueio",
  label239: "Você não tem PIN de bloqueio",
  label240: "Seu PIN de bloqueio é",
  label241: "Adicionar PIN",
  label242: "Modificar PIN",
  label243: "Bloquear agora",
  label244: "Para bloquear o aplicativo agora é necessário um PIN",
  label245: "Bloquear o aplicativo agora",
  label246: "Bloquear ao iniciar o aplicativo",
  label247: "Para iniciar o aplicativo sempre bloqueado é necessário um PIN",
  label248: "O aplicativo será iniciado sempre bloqueado",
  label249: "Mudar idioma",
  label250: "Mudar o idioma do aplicativo",
  label251: "Mostrar botões em reprodução",
  label252:
    "Mostrar ou ocultar botões (não programar, programar sempre, editar, adicionar)",
  label253: "Ativar restrição a conteúdo explícito",
  label254:
    "Restringe a programação de músicas com conteúdo inapropriado ou abusivo",
  label255: "Modo de hibernação",
  label256: "Editar configuração de hibernação",
  label257: "Adicionar uma configuração de hibernação",
  label258: "Adicionar",
  label259: "O idioma do aplicativo foi alterado para Inglês com sucesso.",
  label260:
    "Erro, algo deu errado ao mudar o idioma do aplicativo para Inglês.",
  label261: "O idioma do aplicativo foi alterado para Espanhol com sucesso.",
  label262:
    "Erro, algo deu errado ao mudar o idioma do aplicativo para Espanhol.",
  label263: "O idioma do aplicativo foi alterado para Francês com sucesso.",
  label264:
    "Erro, algo deu errado ao mudar o idioma do aplicativo para Francês.",
  label265: "Barra de favoritos ativada com sucesso.",
  label266: "Barra de favoritos desativada com sucesso.",
  label267: "Erro, algo deu errado ao ativar a barra de favoritos.",
  label268: "Erro, algo deu errado ao desativar a barra de favoritos.",
  label269: "O aplicativo foi bloqueado com sucesso.",
  label270: "O aplicativo foi desbloqueado com sucesso.",
  label271: "Erro, algo deu errado ao bloquear o aplicativo.",
  label272: "Erro, algo deu errado ao desbloquear o aplicativo.",
  label273: "Bloqueio ao iniciar o aplicativo ativado com sucesso.",
  label274: "Bloqueio ao iniciar o aplicativo desativado com sucesso.",
  label275:
    "Erro, algo deu errado ao ativar o bloqueio ao iniciar o aplicativo.",
  label276:
    "Erro, algo deu errado ao desativar o bloqueio ao iniciar o aplicativo.",
  label277: "Botões em reprodução ativados.",
  label278: "Botões em reprodução desativados.",
  label279: "Erro, algo deu errado ao ativar botões em reprodução.",
  label280: "Erro, algo deu errado ao desativar botões em reprodução.",
  label281: "Restrição a conteúdo explícito ativada.",
  label282: "Restrição a conteúdo explícito desativada.",
  label283: "Erro, algo deu errado ao ativar a restrição a conteúdo explícito.",
  label284:
    "Erro, algo deu errado ao desativar a restrição a conteúdo explícito.",
  label285: "Modo de hibernação ativado.",
  label286: "Modo de hibernação desativado.",
  label287: "Erro, algo deu errado ao ativar o modo de hibernação.",
  label288: "Erro, algo deu errado ao desativar o modo de hibernação.",
  label289: "Adicionar \n PIN",
  label290: "Editar \n PIN",
  label291: "PIN Atual",
  label292: "Novo PIN",
  label293: "Obrigatório (o PIN deve ter 4 dígitos)",
  label294: "Confirmar o novo PIN",
  label295: "Erro, apenas números são permitidos no seu PIN e 0000 não é permitido",
  label296: "Erro, o PIN deve ter 4 dígitos.",
  label297: "Erro, a confirmação não é igual ao novo PIN.",
  label298: "Erro, o PIN é igual ao anterior. Por favor, insira um novo PIN.",
  label299: "O PIN foi salvo com sucesso.",
  label300: "O PIN foi modificado com sucesso.",
  label301: "Erro, algo deu errado ao salvar o PIN.",
  label302: "Erro, algo deu errado ao modificar o PIN.",
  label303: "Adicionar uma configuração \n de hibernação",
  label304: "Editar uma configuração \n de hibernação",
  label305: "Estado",
  label306: "Horário de hibernação",
  label307: "Em",
  label308: "Todo dia",
  label309: "Dias especificados",
  label310: "A configuração de hibernação foi salva com sucesso.",
  label311: "Erro, algo deu errado ao salvar a configuração de hibernação.",
  label312: "A configuração de hibernação foi editada com sucesso.",
  label313: "Erro, algo deu errado ao editar a configuração de hibernação.",
  label314: "Planejador ativado com sucesso.",
  label315: "Planejador desativado com sucesso.",
  label316: "Erro, algo deu errado ao ativar o planejador.",
  label317: "Erro, algo deu errado ao desativar o planejador.",
  label318: "Planejador Ativado",
  label319: "Planejador Desativado",
  label320: "Adicionar plano",
  label323: "Minhas playlists",
  label324: "Aleatório",
  label325: "O plano foi salvo com sucesso.",
  label326: "Erro, algo deu errado ao salvar o plano.",
  label327: 'Erro, a hora "De" é menor que a hora "Até".',
  label328: "Erro, já existe um plano nesse intervalo de tempo.",
  label329: "Carregar Anúncio",
  label330: "O plano foi excluído com sucesso.",
  label331: "Erro, algo deu errado ao excluir o plano.",
  label332: "Aleatório ativado com sucesso.",
  label333: "Aleatório desativado com sucesso.",
  label334: "Erro, algo deu errado ao ativar o aleatório.",
  label335: "Erro, algo deu errado ao desativar o aleatório.",
  label336: "Arraste \n seu anúncio \n ou clique \n nesta área",
  label337: "Tamanho",
  label338: "(Apenas mp3. Tamanho máximo 5 MB).",
  label339: "Enviar anúncio",
  label340: "Enviando anúncio...",
  label341: "Erro, (APENAS EM MP3).",
  label342: "O nome do anúncio deve ter de 1 a 50 caracteres.",
  label343:
    "Erro, algo deu errado ao enviar o anúncio, verifique se é um arquivo MP3 e se seu tamanho não ultrapassa 5 MB.",
  label344: "O anúncio foi enviado com sucesso.",
  label345: "O anúncio",
  label346: "Erro, algo deu errado ao excluir o anúncio",
  label347: "Deseja excluir o anúncio",
  label348: "O anúncio será excluído",
  label349: "Todos os dias",
  label350: "Segunda a sexta",
  label351: "Sábado e domingo",
  label352: "Dia exato",
  label353: "Hora exata",
  label354: "Intervalo",
  label355: "Cada",
  label356: "Frequência",
  label357: "músicas",
  label358: "Limite de anúncios por conjunto",
  label359: "Início da pausa",
  label360: "Fim da pausa",
  label361: "Adicionar \n pausa",
  label362: "Dias",
  label363: "Horário",
  label364: "Intervalo de tempo",
  label365: "Limite de anúncios por conjunto",
  label366: "A cada x minutos",
  label367: "A cada x músicas",
  label368: "Dia ou dias",
  label369: "Hora",
  label370: "Dia",
  label371: "Definir início",
  label372: "Definir fim",
  label373: "Erro, já existe uma pausa com esse nome.",
  label374: "Erro, já existe uma pausa nesse intervalo de tempo.",
  label375: "Erro, já existe uma pausa para essa hora exata.",
  label376: "O meio",
  label377: "Erro, algo deu errado ao excluir o meio",
  label378: "Deseja excluir o meio",
  label379: "O meio será excluído",
  label380: "até",
  label381: "Alto",
  label382: "Normal",
  label383:
    "Você pode carregar qualquer tipo de meio (spot, anúncio, entre músicas, ID de rádio) \n ou criar seu próprio tipo. Escolha o arquivo a carregar, dê a ele um nome, indique que tipo de meio é, \n atribua todos os valores, se houver versões, você pode carregá-las e indicar os locais onde elas serão reproduzidas.",
  label384: "Definir início e fim",
  label385: "Escolher…",
  label386: "Início",
  label387: "Fim",
  label388: "Marca ou produto",
  label389: "Empresa anunciante",
  label390: "Valores",
  label391: "Infinitas",
  label392: "Locais onde \n será publicado",
  label393: "Todos os usuários (locais)",
  label394: "Selecionar usuários (locais) ou grupos",
  label395: "Usuários (Locais)",
  label396: "Grupos",
  label397: "Erro, algo deu errado ao carregar o meio.",
  label398: "O meio foi salvo com sucesso.",
  label399: `Erro, você deve selecionar uma prioridade.`,
  label400: `Erro, você não selecionou nenhum local para publicar.`,
  label401: `Erro, você deve selecionar uma marca ou produto.`,
  label402: `Erro, você deve selecionar um anunciante.`,
  label403: `Erro, você deve selecionar um tipo de meio.`,
  label404: `Erro, você deve adicionar um nome para o meio.`,
  label405: `Filtrar por`,
  label406: `Arraste aqui os \n usuários ou grupos`,
  label407: `Erro, algo deu errado ao editar o meio.`,
  label408: `O meio foi editado com sucesso.`,
  label409: `Adicionar \n tipo de meio`,
  label410: `Erro, algo deu errado ao salvar o tipo de meio.`,
  label411: `O Tipo de meio foi salvo com sucesso.`,
  label412: `Erro, o nome do tipo de meio deve ter de 1 a 50 caracteres.`,
  label413: `Adicionar \n anunciante`,
  label414: `Erro, algo deu errado ao salvar o anunciante.`,
  label415: `O anunciante foi salvo com sucesso.`,
  label416: `Erro, o nome do anunciante deve ter de 1 a 50 caracteres.`,
  label417: `Adicionar \n marca ou produto`,
  label418: `Escrever aqui para pesquisar...`,
  label419: `Erro, algo deu errado ao salvar a marca ou produto.`,
  label420: `A marca ou produto foi salva com sucesso.`,
  label421: `Erro, o nome da marca ou produto deve ter de 1 a 50 caracteres.`,
  label422: `Carregar \n versões para`,
  label423: `A Versão`,
  label424: `Erro, algo deu errado ao excluir a versão`,
  label425: `Deseja excluir a versão`,
  label426: `A versão será excluída`,
  label427: `Cancelar`,
  label428: `Erro, algo deu errado ao carregar a versão.`,
  label429: `A versão foi carregada com sucesso.`,
  label430: `Erro. Não foi possível carregar a versão, feche a janela e tente novamente.`,
  label431: `Calendário ativado com sucesso.`,
  label432: `Calendário desativado com sucesso.`,
  label433: `Erro, algo deu errado ao ativar o calendário.`,
  label434: `Erro, algo deu errado ao desativar o calendário.`,
  label435: `Criar relógio`,
  label436: `Para criar um relógio, você deve arrastar os meios que aparecem à esquerda para a direita. Em seguida, você pode arrastá-los para cima ou para baixo para ordená-los. O meio "COMERCIAL" tem por padrão 16 comerciais (spots ou anúncios). Os outros têm apenas 1 meio por padrão. Em ambos os casos, é possível editar esse número pressionando a caneta.`,
  label437: `Pesquisa de meios`,
  label438: `Arraste os \n meios aqui`,
  label439: `Erro, o nome do relógio deve ter de 1 a 50 caracteres.`,
  label440: `Erro, algo deu errado ao salvar o relógio.`,
  label441: `O relógio foi salvo com sucesso.`,
  label442: `Erro, você não adicionou nenhum meio.`,
  label443: `Gerenciar \n relógios`,
  label444: `Editar ou excluir, se você excluir o relógio, ele desaparecerá do calendário.`,
  label445: `O relógio`,
  label446: `Erro, algo deu errado ao excluir o relógio`,
  label447: `Deseja excluir o relógio`,
  label448: `O relógio será excluído`,
  label449: `Quantidade`,
  label450: `Blocos de`,
  label451: `Erro, algo deu errado ao editar`,
  label452: `Para editar um relógio, você deve arrastar os meios que aparecem à esquerda para a direita. Em seguida, você pode arrastá-los para cima ou para baixo para ordená-los. O meio "COMERCIAL" tem por padrão 16 comerciais (spots ou anúncios). Os outros têm apenas 1 meio por padrão. Em ambos os casos, é possível editar esse número pressionando a caneta.`,
  label453: `O relógio foi editado com sucesso.`,
  label454: `Atribua um relógio a cada intervalo de tempo. Você pode deixar intervalos sem um relógio atribuído; nesse caso, apenas música será reproduzida (sem meios).`,
  label455: `Sem relógio atribuído`,
  label456: `Erro, algo deu errado ao salvar o relógio em um dia normal.`,
  label457: `O relógio no dia normal foi salvo com sucesso.`,
  label458: `Atribuir relógios a dia especial`,
  label459: `Repetições`,
  label460: `Dia pontual`,
  label461: `Intervalo de dias`,
  label462: `Erro, o nome do dia especial deve ter de 1 a 50 caracteres.`,
  label463: `O relógio no dia especial foi salvo com sucesso.`,
  label464: `Erro, algo deu errado ao salvar o relógio em um dia especial.`,
  label465: `Erro, já existe um relógio atribuído a esse dia pontual e sua repetição coincide ou está dentro de outro intervalo.`,
  label466: `Erro, já existe um relógio atribuído nesse intervalo.`,
  label467: `Erro, a data final é maior que a data inicial.`,
  label468: `Editar ou excluir \n estrutura do dia`,
  label469: `Editar ou excluir, ao alterar qualquer valor dos seletores, a edição será feita automaticamente; se você excluir o dia, ele desaparecerá do calendário.`,
  label470: `Esta intervalo na estrutura do dia foi atualizado com sucesso.`,
  label471: `Erro. Algo deu errado ao editar este intervalo.`,
  label472: `Última conexão`,
  label473: `Conectado em `,
  label474: `Finalizar`,
  label475: `Gerenciar \n meios`,
  label476: `Gerenciar \n anunciantes`,
  label477: `Gerenciar \n marcas ou produtos`,
  label478: `Editar ou excluir, você não poderá excluir um tipo de meio já atribuído a algum meio já carregado.`,
  label479: `Editar ou excluir, você não poderá excluir uma empresa ou anunciante já atribuído a algum meio já carregado.`,
  label480: `Editar ou excluir, você não poderá excluir uma marca ou produto já atribuído a algum meio já carregado.`,
  label481: `O tipo de meio`,
  label482: `A empresa ou anunciante`,
  label483: `A marca ou produto`,
  label484: `Erro, algo deu errado ao excluir o tipo de meio`,
  label485: `Erro, algo deu errado ao excluir a empresa ou anunciante`,
  label486: `Erro, algo deu errado ao excluir a marca ou produto`,
  label487: `Deseja excluir o tipo de meio`,
  label488: `Deseja excluir a empresa ou anunciante`,
  label489: `Deseja excluir a marca ou produto`,
  label490: `O tipo de meio será excluído`,
  label491: `A empresa ou anunciante será excluída`,
  label492: `A marca ou produto será excluída`,
  label493: `Ok`,
  label494: `Aviso`,
  label495: `Não é possível excluir este tipo de meio, pois está atribuído a um ou vários meios já carregados. Se desejar excluí-lo, deverá primeiro excluir os meios associados a ele.`,
  label496: `Não é possível excluir esta empresa ou anunciante, pois está atribuída a um ou vários meios já carregados. Se desejar excluí-la, deverá primeiro excluir os meios associados a ela.`,
  label497: `Não é possível excluir esta marca ou produto, pois está atribuída a um ou vários meios já carregados. Se desejar excluí-la, deverá primeiro excluir os meios associados a ela.`,
  label498: `Você excedeu o limite de meios disponíveis para carregamento em sua assinatura. Entre em contato conosco para ampliar os limites de sua assinatura.`,
  label499: `Você excedeu o limite de versões por meio disponíveis para sua assinatura. Entre em contato conosco para ampliar os limites de sua assinatura.`,
  label500: `Você excedeu o limite de tamanho de anúncio disponível para sua assinatura. Entre em contato conosco para ampliar os limites de sua assinatura.`,
  label501: `Você excedeu o limite de tipos de meios que pode criar disponíveis para sua assinatura. Entre em contato conosco para ampliar os limites de sua assinatura.`,
  label502: `Atualmente, seu limite de criação de tipos de meios é de: `,
  label503: `Atualmente, seu limite de carregamento de meios é de: `,
  label504: `Atualmente, seu limite de carregamento de versões é de: `,
  label505: `Atualmente, seu limite de tamanho por anúncio é de: `,
  label506: `Deseja excluir a pausa`,
  label507: `A pausa será excluída`,
  label508: `Adicionar / remover`,
  label509: `Número de anúncios carregados`,
  label510: `Adicionar ou remover anúncios`,
  label511: `Adicionar ou remover anúncios, você deve arrastar os anúncios que aparecem à esquerda para a direita. Em seguida, você pode arrastá-los para cima ou para baixo para ordená-los.`,
  label512: `Anúncios para a pausa`,
  label513: `Pesquisa de anúncios`,
  label514: `Arraste os \n anúncios aqui`,
  label515: `Erro, algo deu errado ao salvar a pausa.`,
  label516: `A pausa foi salva com sucesso.`,
  label517: `Erro, algo deu errado ao editar a pausa.`,
  label518: `A pausa foi editada com sucesso.`,
  label519: `Relatório discriminado pela seleção de meio já excluído (Resumo)`,
  label520: `Relatório discriminado pela seleção de meio já excluído (Detalhado)`,
  label521: `Meio já excluído`,
  label522: `Meus Estilos`,
  label523: `Você pode criar seus próprios estilos musicais e, em seguida, ir para "GERENCIAR MÚSICAS" e atribuir as músicas que deseja que pertençam a esse ou esses estilos.`,
  label524: `Digite o nome do estilo a ser pesquisado...`,
  label525: `O estilo`,
  label526: `Erro, algo deu errado ao excluir o estilo`,
  label527: `Deseja excluir o estilo`,
  label528: `O estilo será excluído`,
  label529: `Criar \n estilo`,
  label530: `Criar estilo`,
  label531: `Erro, algo deu errado ao salvar o estilo.`,
  label532: `O estilo foi salvo com sucesso.`,
  label533: `Erro, o nome do estilo deve ter de 1 a 50 caracteres.`,
  label534: `Erro, algo deu errado ao editar o estilo.`,
  label535: `O estilo foi editado com sucesso.`,
  label536: `Editar \n estilo`,
  label537: `Gerenciador de músicas`,
  label538: `Você pode mudar o estilo de uma música para um criado pelo usuário, pode mudar o nome da música, indicá-la como explícita (linguagem inadequada) ou marcá-la como favorita ou indesejada. Também pode atribuí-la a uma playlist manual.`,
  label539: `Digite aqui o nome da música ou intérprete para pesquisar...`,
  label540: `Pesquisar`,
  label541: `Mostrar`,
  label542: `Todas`,
  label543: `Somente editadas`,
  label544: `Favoritas`,
  label545: `Indesejadas`,
  label546: `Editar \n música`,
  label547: `Padrão`,
  label548: `Erro, os campos de nome e palavra-passe são obrigatórios.`,
  label549: `Nome original`,
  label550: `Estilo musical`,
  label551: `Humor`,
  label552: `Preferência`,
  label553: `Música explícita`,
  label554: `(conteúdo inadequado ou abusivo)`,
  label555: `Erro, algo deu errado ao editar a música.`,
  label556: `A música foi editada com sucesso.`,
  label557: `Permissões para utilizar a aplicação de música, prima o botão Guardar para aplicar as alterações.`,
  label558: `Deseja desmarcar esta música como favorita`,
  label559: `Deseja marcar esta música como favorita`,
  label560: `não será mais sempre incluída, apenas de maneira normal.`,
  label561: `será sempre incluída nas playlists associadas ao estilo desta música.`,
  label562: `Erro, algo deu errado ao desmarcar esta música como favorita.`,
  label563: `Erro, algo deu errado ao marcar esta música como favorita.`,
  label564: `A música foi desmarcada como favorita com sucesso.`,
  label565: `A música foi marcada como favorita com sucesso.`,
  label566: `Deseja desmarcar esta música como indesejada`,
  label567: `Deseja marcar esta música como indesejada`,
  label568: `voltará a ser reproduzida de maneira normal.`,
  label569: `não será mais reproduzida.`,
  label570: `Erro, algo deu errado ao desmarcar esta música como indesejada.`,
  label571: `Erro, algo deu errado ao marcar esta música como indesejada.`,
  label572: `A música foi desmarcada como indesejada com sucesso.`,
  label573: `A música foi marcada como indesejada com sucesso.`,
  label574: `Escolher playlist...`,
  label575: `País`,
  label576: `Cidade`,
  label577: `Província`,
  label578: `Erro, o nome já está em uso, escolha outro nome.`,
  label579: `Você pode criar suas próprias playlists de duas formas diferentes. Automática: escolha os estilos e o programa escolherá as músicas. Manual: escolha as músicas uma a uma. Também pode excluir playlists ou modificá-las.`,
  label580: `A playlist`,
  label581: `Erro, algo deu errado ao excluir a playlist`,
  label582: `Deseja excluir a playlist`,
  label583: `A playlist será excluída`,
  label584: `Playlist Manual`,
  label585: `Playlist Automática`,
  label586: `Digite aqui o nome da playlist para pesquisar...`,
  label587: `Criar playlist`,
  label588: `Anúncios da pausa`,
  label589: `Criar \n playlist`,
  label590: `Escolha uma opção.`,
  label591: `Automática`,
  label592: `Manual`,
  label593: `escolhe os estilos e o programa escolhe as músicas.`,
  label594: `escolhe as músicas uma a uma.`,
  label595: `Selecione o tipo de playlist a criar...`,
  label596: `Nome da nova playlist (obrigatório)...`,
  label597: `Descrição (opcional)...`,
  label598: `Próximo`,
  label599: `Arraste da esquerda para a direita os estilos desejados. Você pode arrastar um estilo várias vezes para programar mais músicas desse estilo. Em seguida, no painel à direita, você pode arrastar para cima e para baixo os estilos para ordená-los. Também pode modificar as configurações do estilo para programar músicas mais calmas ou agitadas, etc.`,
  label600: `Digite aqui o nome do estilo para pesquisar...`,
  label601: `Pesquisador de estilos musicais`,
  label602: `Estilos`,
  label603: `Meus estilos`,
  label604: `Duração (em horas)`,
  label605: `Restringir explícitas`,
  label606: `Arraste os estilos aqui`,
  label607: `Erro, algo deu errado ao criar a playlist automática.`,
  label608: `A playlist automática foi criada com sucesso.`,
  label609: `Erro, nenhum estilo selecionado para criar a playlist.`,
  label610: `Editar \n playlists automáticas`,
  label611: `Arraste da esquerda para a direita os estilos desejados. Você pode arrastar um estilo várias vezes para programar mais músicas desse estilo. Em seguida, no painel à direita, você pode arrastar para cima e para baixo os estilos para ordená-los. Também pode modificar as configurações do estilo para programar músicas mais calmas ou agitadas, etc.`,
  label612: `Erro, algo deu errado ao editar a playlist automática.`,
  label613: `A playlist automática foi editada com sucesso.`,
  label614: `Erro, o nome da playlists deve ter pelo menos um caractere.`,
  label615: `Modificar`,
  label616: `Somente sucessos`,
  label617: `Sucessos e recomendadas`,
  label618: `Sucessos, recomendadas e variadas`,
  label619: `Busque as músicas que deseja, pelo nome ou pelo nome do intérprete. Em seguida, arraste cada música da esquerda para a direita. No painel à direita, você pode arrastar para cima e para baixo as músicas para ordená-las.`,
  label620: `Pesquisador de músicas`,
  label621: `Arraste as músicas aqui`,
  label622: `Erro, algo deu errado ao criar a playlist manual.`,
  label623: `A playlist manual foi criada com sucesso.`,
  label624: `Erro, o limite de músicas por playlist é de`,
  label625: `Editar \n playlist manual`,
  label626: `Erro, algo deu errado ao editar a playlist manual.`,
  label627: `A playlist manual foi editada com sucesso.`,
  label629: `Número de músicas`,
  label630: `(Administrador) - `,
  label631: `(Usuário) - `,
  label632: `às playlists manuais`,
  label633: `Só é possível adicionar a playlists manuais criadas por você.`,
  label634: `Playlists de destino`,
  label635: `Adicionando...`,
  label636: `A música foi adicionada com sucesso.`,
  label637: `Erro, algo deu errado ao adicionar a música.`,
  label638: `Escolher um grupo...`,
  label639: `Tipo de conexão`,
  label640: `Escolher um tipo de conexão...`,
  label641: `Pesquisa pontual`,
  label642: `Resultados: `,
  label643: `Aplicar Filtro`,
  label644: `Digite aqui o nome de usuário para pesquisar...`,
  label645: `Músicas não explícitas`,
  label646: `Músicas explícitas`,
  label647: `Escolher um estilo...`,
  label648: `Escolher um estilo do usuário...`,
  label649: `Escolher um humor...`,
  label650: `Conteúdo explícito`,
  label651: `Escolher tipo de conteúdo...`,
  label652: `Critério do usuário`,
  label653: `Estilos do Usuário`,
  label654: `Erro no arquivo de áudio.`,
  label655: `Senha Atual`,
  label656: `País`,
  label657: `Província`,
  label658: `Cidade`,
  label659: `Hibernação`,
  label660: `Erro, a hora de deve ser maior que a hora até.`,
  label661: `Erro, a diferença entre as horas deve ser no mínimo de 5 minutos.`,
  label662: `Erro, você deve selecionar pelo menos um dia.`,
  label663: `Erro, há (`,
  label664: `) plano(s) que interferem no intervalo de tempo que você deseja criar no(s) dia(s) selecionado(s).`,
  label665: `Todos os planos do dia foram removidos, `,
  label666: `Erro, algo deu errado ao remover todos os planos do dia, `,
  label667: `deseja remover todos os planos do dia?`,
  label668: `Todos os planos serão removidos para este dia, `,
  label669: `Volume geral do aplicativo`,
  label670: `A escala de volume do aplicativo varia de 0 a 100`,
  label671: `O volume do aplicativo foi atualizado com sucesso.`,
  label672: `Erro ao atualizar o volume do aplicativo.`,
  label673: `Silenciar o aplicativo`,
  label674: `Desmutar o aplicativo`,
  label675: `Desativar o som do aplicativo`,
  label676: `Ativar o som do aplicativo`,
  label677: "O aplicativo foi silenciado com sucesso.",
  label678: "O aplicativo foi desmutado com sucesso.",
  label679: "Erro, algo deu errado ao silenciar o aplicativo.",
  label680: "Erro, algo deu errado ao desmutar o aplicativo.",
  label681: "Restaurar tudo",
  label682: "A personalização dos seus usuários foi restaurada com sucesso.",
  label683:
    "Erro, algo deu errado ao restaurar a personalização dos seus usuários.",
  label684: "Deseja restaurar a personalização dos seus usuários?",
  label685:
    "A personalização dos seus usuários será removida e será restaurada para as configurações padrão de cada um deles.",
  label686: "Sem descrição",
  root: {
    1: "DEUS",
    2: "SUPER-RAIZ",
    3: "RAIZ",
    4: "SUPER-ADMINISTRADOR",
    5: "ADMINISTRADOR",
    6: "SUPERVISOR",
    7: "USUÁRIO-ADMINISTRADO",
    8: "USUÁRIO-INDIVIDUAL",
  },
  label687: "Conta",
  label688: "Gestão da Conta",
  label689: "Alterar Senha",
  label690: "Alterar Dados da Conta (Nome da Empresa, Endereço, CIF/NIF, etc.)",
  label691: "Baixar Faturas ou Atualizar Dados do Cartão de Débito/Crédito",
  label692: "Alterar ou Cancelar a Assinatura",
  label693: "Alteração de senha da conta atual.",
  label694: "Nova senha",
  label695: "A senha foi modificada com sucesso.",
  label696: "Erro, algo deu errado ao modificar a senha no amember.",
  label697: "Erro, algo deu errado ao modificar a senha.",
  label698:
    "Instale com permissões de Administrador. No Windows 10, basta inserir o nome de usuário e a senha do administrador ao instalar o programa. No Windows 7, a conta usada para a instalação deve ser de administrador. Se você quiser instalar em uma conta padrão (não administrador), transforme o usuário em administrador, instale o programa e depois volte a transformá-lo em usuário padrão. Em qualquer caso, verifique se nenhum programa está bloqueando o acesso à Internet para o aplicativo (antivírus, firewall, etc.).",
  label699:
    'Para instalar o programa em um computador Mac OS, faça o seguinte: vá para Preferências do Sistema > Segurança e Privacidade. Clique no cadeado no canto inferior esquerdo e insira a senha do usuário para desbloquear o Mac. Marque a opção "De qualquer lugar" na seção "Permitir aplicativos baixados de".',
  label700:
    "Recomendamos instalar o aplicativo e ativar as atualizações automáticas diretamente pela Google Play Store. Se instalado fora da loja, as atualizações do aplicativo serão gerenciadas por você, verificando a última versão do aplicativo publicada nesta página.",
  label701: "Baixadas",
  label702: "Importante",
  label703:
    "Esta área é pública e não requer senha para acessar. Copie o link desta página abaixo e compartilhe com todos os usuários que precisam baixar o programa.",
  label704: "Última versão do aplicativo",
  label705: "Ir",
  label706: "Instruções",
  label707: "Requisitos técnicos",
  label708:
    'Sistema operacional Windows 7 ou superior (Windows 10 recomendado). Na versão Windows N, o aplicativo não pode ser usado, pois não possui o "Media Feature Pack", consulte a área de suporte neste caso.',
  label709:
    "16 GB de espaço disponível no disco rígido (32 GB recomendados ou mais)",
  label710: "4 GB de RAM ou mais",
  label711: "Placa de som",
  label712: "Conexão com a Internet",
  label713: "Arquitetura do processador ARM ou ARM64",
  label714: "2 GB de RAM (4 GB recomendados ou mais)",
  label715: "Copiar URL da Área de baixadas",
  label716: "Copiar URL do Reprodutor Web",
  label717: "Suporte",
  label718:
    "Para obter <font color=#FFFFFFF'>instruções</font> sobre como usar a plataforma e <font color=#FFFFFFF'>perguntas frequentes</font>, por favor",
  label719: "Ajuda e Perguntas Frequentes",
  label720: "Mais Opções",
  label721: "Mais Informações",
  label722: "Certificado",
  label723: "URL da Área de baixadas copiada com sucesso.",
  label724: "URL do Reprodutor Web copiada com sucesso.",
  label725: "Atualizar, Desfazer Mudanças",
  label726: "Redefinir a Personalização de Todos os Seus Usuários",
  label727: "Baixar Certificado",
  label728: "certificado_freedom",
  label729: "Informações",
  label730:
    "Sistema operacional Mac OS Catalina (10.15.7) ou superior (compatível também com versões anteriores a Catalina, mas com atualização manual do programa)",
  label731: "Música",
  label732: "Deseja encerrar a sessão?",
  label733: "Entrar em contato por ticket",
  label734: "Entrar em contato por WhatsApp",
  label735: "Usuário suspenso pelo administrador",
  label736: "Suspender",
  label737: "Ativar",
  label738: "Suspenso pelo administrador",
  label739: "Usuário ativado com sucesso",
  label740: "Usuário suspenso com sucesso",
  label741: "Erro, algo deu errado ao ativar o usuário",
  label742: "Erro, algo deu errado ao suspender o usuário",
  label743: "Listas de reprodução favoritas",
  label744: "Suspender",
  label745: "Ativar",
  label746: "Deseja suspender temporariamente este usuário?",
  label747: "Deseja ativar este usuário?",
  label748: "Permissões de aplicações",
  label749: "Permissão ativada com sucesso",
  label750: "Permissão desativada com sucesso",
  label751: "Erro ao ativar a permissão",
  label752: "Erro ao desativar a permissão",
  label753: "Solicitar PIN ativado",
  label754: "Solicitar PIN desativado",
  label755: "Erro ao ativar a solicitação de PIN",
  label756: "Erro ao desativar a solicitação de PIN",
  label757: "Solicitar PIN, Ativado",
  label758: "Solicitar PIN, Desativado",
  label759: "Adicionar",
  label760: "Lista de reprodução adicionada aos favoritos com sucesso",
  label761:
    "Não foi adicionada porque a lista de reprodução já existe nos favoritos",
  label762: "Erro, algo deu errado ao adicionar a playlist aos favoritos",
  label763: "Adicionando...",
  label764: "Adicionar às listas de reprodução favoritas",
  label765: "Alias",
  label766: "Erro, a senha contém espaços em branco (não permitido).",
  label767: "Erro, é obrigatório confirmar sua senha.",
  label768: "Permissões do aplicativo",
  label769: "Licenças",
  label770: "Usuários suspensos",
  label771: "O idioma do aplicativo foi alterado para Português com sucesso.",
  label772:
    "Erro, algo deu errado ao alterar o idioma do aplicativo para Português.",
  label773: "Intérpretes bloqueados",
  label774: "Adicionar aos artistas bloqueados",
  label775: "Escreva o nome do intérprete a pesquisar...",
  label776:
    "O intérprete foi adicionado com sucesso aos intérpretes bloqueados",
  label777:
    "O intérprete não foi adicionado, já existe em intérpretes bloqueados",
  label778:
    "Erro, algo deu errado ao adicionar o intérprete aos intérpretes bloqueados",
  label779: "O intérprete",
  label780: "foi removido com sucesso do intérprete bloqueado.",
  label781: "Erro, algo deu errado ao remover o intérprete desta lista",
  label782: "Você deseja remover intérpretes bloqueados,",
  label783: "O intérprete será removido",
  label784: "de intérprete bloqueado.",
  label785: "Configurações",
  label786: "Assinatura inválida, entre em contato com o provedor de serviços",
  label787: `Erro, atingiu o limite de contas contratadas.`,
  label788: `Erro, algo correu mal ao criar o utilizador.`,
  label789: "Você não tem permissão para criar, editar ou excluir usuários.",
  label790: "Você não tem permissão para acessar ou ver licenças.",
  label791: "Você não tem permissão para redefinir a personalização de todos os usuários.",
  label792: "Você não tem permissão para ver a playlist favorita de um usuário.",
  label793: "Você não tem permissão para adicionar ou remover a playlist favorita de um usuário.",
  label794: "Você não tem permissão para ver o planejador de playlists de um usuário.",
  label795: "Você não tem permissão para adicionar, editar ou excluir playlists no planejador de playlists de um usuário.",
  label796: "Você não tem permissão para ver o planejador de anúncios de um usuário.",
  label797: "Você não tem permissão para adicionar, editar ou excluir anúncios no planejador de anúncios de um usuário.",
  label798: "Você não tem permissão para clonar usuários.",
  label799: "Você não tem permissão para acessar as preferências do usuário.",
  label800: "Você não tem permissão para acessar a personalização do usuário.",
  label801: "Você não tem permissão para acessar as permissões de aplicativos do usuário.",
  label802: "Você não tem permissão para suspender um usuário.",
  label803: "Você não tem permissão para ver as playlists do administrador.",
  label804: "Você não tem permissão para criar, editar ou excluir playlists do administrador.",
  label805: "Você não tem permissão para ver as playlists favoritas do administrador.",
  label806: "Você não tem permissão para adicionar ou remover playlists favoritas do administrador.",
  label807: "Você não tem permissão para ver os estilos do administrador.",
  label808: "Você não tem permissão para criar, editar ou excluir estilos do administrador.",
  label809: "Você não tem permissão para acessar o gerenciador de músicas.",
  label810: "Você não tem permissão para ver intérpretes bloqueados.",
  label811: "Você não tem permissão para adicionar ou remover intérpretes bloqueados.",
  label812: "Você não tem permissão para ver anúncios carregados.",
  label813: "Você não tem permissão para carregar ou excluir anúncios.",
  label814: "Você não tem permissão para acessar suporte e downloads.",
  label815: "Editar Seleção",
  label816: "Selecionar Tudo",
  label817: "Supervisores",
  label818: "Criar ou editar supervisores e limitar suas ações.",
  label819: "As permissões foram salvas com sucesso.",
  label820: "Erro, algo deu errado ao salvar as permissões.",
  label821: "Supervisor suspenso pelo administrador.",
  label822: "O estilo foi alterado para as músicas selecionadas.",
  label823: "As músicas selecionadas foram alteradas para o estilo padrão.",
  label824: "As músicas selecionadas foram adicionadas às playlists selecionadas.",
  label825: "As músicas selecionadas foram marcadas como explícitas com sucesso.",
  label826: "As músicas selecionadas foram desmarcadas como explícitas com sucesso.",
  label827: "As músicas selecionadas foram marcadas como 'gostei' com sucesso.",
  label828: "As músicas selecionadas foram desmarcadas como 'gostei' com sucesso.",
  label829: "As músicas selecionadas foram marcadas como 'não desejadas' com sucesso.",
  label830: "As modificações foram apagadas para as músicas selecionadas.",
  label831: "Erro, algo deu errado ao alterar o estilo das músicas.",
  label832: "Erro, algo deu errado ao alterar as músicas para o estilo padrão.",
  label833: "Erro, algo deu errado ao adicionar as músicas às playlists.",
  label834: "Erro, algo deu errado ao marcar as músicas como explícitas.",
  label835: "Erro, algo deu errado ao desmarcar as músicas como explícitas.",
  label836: "Erro, algo deu errado ao marcar as músicas como 'gostei'.",
  label837: "Erro, algo deu errado ao desmarcar as músicas como 'gostei'.",
  label838: "Erro, algo deu errado ao marcar as músicas como 'não desejadas'.",
  label839: "Erro, algo deu errado ao apagar as modificações das músicas.",
  label840: "Escolher uma ação...",
  label841: "Aplicando...",
  label842: "Aplicar",
  label843: "Alterar para um estilo de usuário",
  label844: "Alterar para o estilo padrão",
  label845: "Adicionar a uma playlist manual",
  label846: "Marcar como explícita",
  label847: "Desmarcar como explícita",
  label848: "Marcar como gostei",
  label849: "Desmarcar como gostei",
  label850: "Marcar como não desejada",
  label851: "Apagar modificações",
  label852: "Músicas selecionadas",
  label853: "Ação a ser realizada",
  label854: "Permissões",
  label855: "Erro, algo deu errado ao editar o supervisor",
  label856: "Editar o supervisor",
  label857: "Observação",
  label858: "Erro, o campo de nome de usuário é obrigatório",
  label859: "Erro, você atingiu o limite de supervisores permitidos.",
  label860: "Erro, algo deu errado ao criar o supervisor.",
  label861: "Criar supervisor",
  label862: "Permissões para o uso do painel de controle, pressione o botão salvar para aplicar as mudanças.",
  label863: "O limite de supervisores que você pode criar é de 5.",
  label864: "Digite o nome do supervisor para pesquisar...",
  label865: "Erro, algo deu errado ao excluir o supervisor",
  label866: "Deseja excluir o supervisor",
  label867: "O supervisor será excluído",
  label868: "Deseja suspender temporariamente este supervisor?",
  label869: "Deseja ativar este supervisor?",
  label870: `A preparar o anúncio...`,
  label871: `Gerar com IA`,
  label872: `Gerar Anúncio com IA`,
  label873: `Sergio (Espanhol - Espanha)`,
  label874: `Lucia (Espanhol - Espanha)`,
  label875: `Andrés (Espanhol - México)`,
  label876: `Mia (Espanhol - México)`,
  label877: `Kevin (Inglês)`,
  label878: `Ruth (Inglês)`,
  label879: `O texto do anúncio deve conter entre 5 e 350 caracteres.`,
  label880: `Erro, o anúncio não foi gerado devido a problemas nos parâmetros.`,
  label881: `Erro, esta conta não possui créditos ativos ou não tem acesso a esta função.`,
  label882: `Erro, o anúncio não foi gerado por falta de créditos. Entre em contato conosco ou aguarde um mês.`,
  label883: `Erro, o anúncio não foi gerado, erro no serviço de IA.`,
  label884: `Erro, o anúncio não foi gerado.`,
  label885: `Gerando anúncio...`,
  label886: `Gerar`,
  label887: `Créditos disponíveis`,
  label888: `Seus créditos são renovados mensalmente.`,
  label889: `Tenha em mente que cada geração reduz 1 crédito, independentemente de você salvar ou não o anúncio gerado.`,
  label890: `Se precisar de mais créditos, Entre em contato conosco.`,
  label891: `Texto`,
  label892: `Vozes`,
  label893: `Escolher uma voz...`,
  label894: `Erro, o nome do anúncio já existe, por favor escolha outro.`
};

export default pt_PT;
