import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable, computed } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
  Text,
  Columns,
  Column,
  Loader,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import { Ad } from "../../models";
import moment from "moment";

import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";

@observer
class AdsUploadModal extends Component {
  @observable ad;

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      messageError: null,
      processing: false,
    };

    this.ad = this.newAd();
    this.handleTogglePlay = this.handleTogglePlay.bind(this);
    this.handleStartPlayingClick = this.handleStartPlayingClick.bind(this);
  }

  newAd = () => {
    const adStore = this.props.store.ads;
    const attrs = {
      name: "",
      size: "",
      origin_type: 1,
      ad_path: null,
      deleted: 0,
    };
    return new Ad(attrs, adStore);
  };

  handleStartPlayingClick() {
    var that = this;
    that.props.store.ui.startPlayingAds(this.ad, 1, false);
  }

  handleTogglePlay() {
    if (this.ad.ad_path) {
      if (!this.props.store.ui.isPlaying) {
        this.props.store.ui.play();
      } else {
        this.props.store.ui.pause();
      }
    }
  }

  handleFileChange = (e) => {
    if (!e || !e.target || !e.target.files || e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];

    if (file) {
      const user = this.props.store.loggedInUser;
      const fileName = file.name;
      const ext = this.getExtension(fileName);
      if (ext != "mp3" && ext != "MP3" && ext != "Mp3" && ext != "mP3") {
        this.setState({ messageError: this.props.store.language.label341 });
        return;
      }
      const lastDot = fileName.lastIndexOf(".");
      const nameEdit = fileName.substring(0, lastDot);
      this.ad.name = nameEdit;
      this.processFile(file, user);
    }
  };

  processFile = async (file, user) => {
    const ffmpeg = new FFmpeg();

    try {
      this.setState({ processing: true });

      await ffmpeg.load();

      await ffmpeg.writeFile("input.mp3", await fetchFile(file));

      await ffmpeg.exec([
        "-i",
        "input.mp3",
        "-af",
        "loudnorm=I=-16:TP=-2:LRA=7",
        "-b:a", 
        "192k", // Establece el bitrate a 192 kbps
        "output.mp3",
      ]);

      const data = await ffmpeg.readFile("output.mp3");

      const mp3File = new File([data.buffer], "output.mp3", {
        type: "audio/mp3",
      });

      let momenStr = moment(new Date()).format("YYYYMMDDHHmmss");
      this.ad.ad_path = new File([mp3File], `${user.id}-AD-${momenStr}.mp3`, {
        type: mp3File.type,
      });
      this.ad.size = (mp3File.size / 1048576).toFixed(2);

      this.handleStartPlayingClick();
    } catch (error) {
      console.error("Error al procesar el archivo:", error);
    } finally {
      this.setState({ processing: false });
    }
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label870}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  };

  validAdName = (value) => {
    if (value.length >= 1 && value.length <= 50) {
      return true;
    } else {
      return false;
    }
  };

  handleChange = (sender, value, name, validation) => {
    const user = this.props.store.loggedInUser;
    if (name === "name") {
      if (this.validAdName(value)) {
        this.setState({ messageError: null });
        this.ad.name = value;
      } else {
        this.setState({ messageError: this.props.store.language.label342 });
      }
    }
  };

  handleClose = () => {
    this.props.store.ui.destroy();
    this.props.onClose && this.props.onClose();
  };

  getMessagerError(value) {
    const messager = {
      0: "UNKNOWN_ERROR",
      1: this.props.store.language.label343,
      2: this.props.store.language.label894
    };
    return messager[value];
  };

  handleSave = () => {
    const { toastManager } = this.props;
    this.setState({ isSaving: true }, () => {
      this.ad.saveFormData().andThen((res, responseError) => {
        if (responseError) {
          let errorData;
          try {
            // Si es una instancia de Error, extraer y parsear su mensaje
            if (responseError instanceof Error) {
              console.error("Raw error object:", responseError);
              errorData = JSON.parse(responseError.message);
            } else if (typeof responseError === "string") {
              errorData = JSON.parse(responseError);
            } else {
              errorData = responseError;
            }
          } catch (e) {
            console.error("Error parsing responseError:", e, responseError);
            errorData = { answer_code: 0, message: "Could not parse error response" };
          }
          // Extraer el answer_code correctamente
          const answerCode = errorData?.answer_code ?? 0;

          toastManager.add(this.getMessagerError(answerCode), {
            appearance: "error",
            autoDismiss: true,
          });
          this.setState({isSaving: false });
        } else {
          toastManager.add(this.props.store.language.label344, {
            appearance: "success",
            autoDismiss: true,
          });
          this.props.store.ui.destroy();
          this.props.onSave && this.props.onSave();
        }
      });
    });
  };

  getDivUploadStyle = () => ({
    minHeight: "300px",
    border: "2px dashed #ccc",
    borderRadius: "16px",
    position: "relative",
  });

  getInputStyle = () => ({
    position: "absolute",
    margin: 0,
    padding: 0,
    width: "100%",
    height: "100%",
    outline: "none",
    opacity: 0,
  });

  getPStyle = () => ({
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "28px",
    whiteSpace: "pre-line",
    color: "#ccc",
    fontSize: "32px",
    lineHeight: "32px",
    fontWeight: "bold",
  });

  @computed
  get canSave() {
    if (this.state.messageError) return false;
    return true;
  }

  renderButtons = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight className="mb-2">
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label340}</strong>
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={
                this.props.store.ui.isError || !this.canSave || !this.ad.ad_path
              }
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label339}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "90%"}
        height={this.props.store.viewMobile ? "560px" : "auto"}
      >
        <ModalHeader>
          <Text
            multiline
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
          >
            {this.props.store.language.label329}
          </Text>
        </ModalHeader>
        <ModalContent>
          <div className="flex flex-col " style={{ height: "100%" }}>
            <Columns>
              <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
                <Field
                  label={this.props.store.language.label53}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  NoteSize="xs"
                  labelNote={this.props.store.language.label76}
                  weight="medium"
                >
                  <TextInput
                    name="name"
                    value={this.ad.name}
                    onChange={this.handleChange}
                    required
                    autocomplete="nope"
                    disabled={this.ad.ad_path === null ? true : false}
                    backgroundColor="blackDark"
                    className="is-fullwidth my-1"
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    invert
                    paddingInput={this.props.store.viewMobile && "20px"}
                  />
                </Field>
              </Column>

              <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
                <div
                  className="is-row"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {this.props.store.ui.showPlayer && (
                    <div className="is-row">
                      {!this.props.store.ui.isError && (
                        <Button
                          icon={
                            this.props.store.ui.isPlaying ? "pause" : "play"
                          }
                          kind="link"
                          color="primary"
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          onClick={() => this.handleTogglePlay()}
                          id="play-button"
                          space
                        />
                      )}
                      <div
                        className={`${
                          this.props.store.viewMobile ? "ml-3" : "ml-2"
                        } is-row`}
                        style={{ alignItems: "center" }}
                        maxLength={this.props.mobile ? 50 : 250}
                      >
                        {this.ad.name && (
                          <Text
                            multiline
                            weight="normal"
                            lead={this.props.store.viewMobile}
                            size={this.props.store.viewMobile ? "sm" : "xl"}
                            color={"primary"}
                          >
                            <span style={{ fontWeight: "bold" }}>
                              {this.props.store.ui.isError
                                ? this.props.store.language.label654
                                : this.ad.name}
                            </span>
                          </Text>
                        )}
                      </div>
                    </div>
                  )}
                  {!this.props.store.ui.isError &&
                    this.props.store.ui.showPlayer && (
                      <div>
                        <Text
                          size={this.props.store.viewMobile ? "md" : "sm"}
                          maxLength={this.props.mobile ? 50 : 250}
                        >
                          {this.props.store.ui.currentDuration == "NaN:NaN"
                            ? "00:00 / 00:00"
                            : this.props.store.ui.currentDuration &&
                              this.props.store.ui.currentProgressTime &&
                              this.props.store.ui.currentProgressTime +
                                " / " +
                                this.props.store.ui.currentDuration}
                        </Text>
                      </div>
                    )}
                </div>
              </Column>
            </Columns>

            <div
              id={"waveAds"}
              style={{ height: this.props.store.ui.showPlayer ? 100 : 0 }}
            />

            {this.state.processing ? (
              this.renderLoader()
            ) : (
              <div style={this.getDivUploadStyle()}>
                <input
                  style={this.getInputStyle()}
                  type="file"
                  onChange={this.handleFileChange}
                  accept=".mp3"
                />
                <p id="p" style={this.getPStyle()}>
                  {this.ad.ad_path === null
                    ? ("+\n" + this.props.store.language.label336).toUpperCase()
                    : (
                        this.props.store.language.label53 +
                        "\n" +
                        this.ad.name +
                        "\n\n" +
                        this.props.store.language.label337 +
                        "\n" +
                        this.ad.size +
                        "MB"
                      ).toUpperCase()}
                  <span
                    className="ml-1"
                    style={{ fontSize: "18px", fontWeight: "normal" }}
                  >
                    {this.ad.ad_path === null
                      ? this.props.store.language.label338
                      : null}
                  </span>
                </p>
              </div>
            )}
            {this.state.messageError && (
              <>
                <br />
                <Panel color="error" className="mt-2" invert>
                  <Text
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    multiline
                  >
                    {this.state.messageError}
                  </Text>
                </Panel>
              </>
            )}
            <div className="my-1"></div>
          </div>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

AdsUploadModal.propTypes = {
  ad: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

AdsUploadModal.defaultProps = {
  ad: null,
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(AdsUploadModal));
