const fr_FR = {
  media_type: {
    1: `Chanson`,
    2: `Identifiant radio`,
    3: `Entre chanson`,
    4: `Commercial`,
    5: `Commercial commencé`,
    6: `Fins commerciales`,
  },
  mood: {
    1: "Très calme",
    2: "Calme",
    3: "Normal",
    4: "Déplacé",
    5: "Très ému",
    6: "Très calme et tranquille",
    7: "Calme et normal",
    8: "Silencieux, normal et émouvant",
    9: "Normal et déplacé",
    10: "Tous",
  },
  languages: {
    1: "Espagnol",  
    2: "Anglais",      
    3: "Français",
    4: "Portugais",
    5: "Estonien",
    6: "Allemand",
    7: "Italien"
  },
  label1: "Idiomes",
  label2: "Chanson supprimée par le fournisseur de services, veuillez modifier la liste de lecture et la supprimer",
  label3: "Chanson supprimée par le fournisseur de services, veuillez la supprimer de cette liste",
  label4: "Lecture",
  label5: "Chargement de l´application...",
  label6: "Panneau de commande",
  label7: "Utilisateur (ne pas utiliser de courrier électronique)",
  label8: "Mot de passe",
  label9: "Se connecter",
  label10: "Erreur, veuillez fournir un nom d'utilisateur et un mot de passe.",
  label11: "Erreur, ce compte n'est pas un administrateur.",
  label12: "Erreur, Utilisateur suspendu!, Contactez ",
  label13: "Erreur, nom d'utilisateur ou mot de passe incorrect.",
  label14: "Erreur, Votre abonnement a expiré. Allez sur ",
  label15:
    "Erreur, impossible de se connecter au serveur, vérifiez votre connexion Internet.",
  label16: "Panneau \nde\n commande",
  label17: "Utilisateurs",
  label18: "Créer, modifier et contrôler \n utilisateurs (locaux)",
  label19: `Cloner les paramètres d'un \n utilisateur vers d'autres`,
  label20: "Personnalisez le logo \n et les données du programme",
  label21: "Créer et modifier \n groupes d'utilisateurs",
  label22: "Télécharger \n rapports d'utilisation",
  label23: "Préférences",
  label24: "Créer et modifier les préférences \n d'un utilisateur",
  label25: "Créer et modifier \n playlist utilisateur",
  label26:
    "Attribuer des listes de lecture ou des hibernations à chaque \n heure de chaque jour",
  label27: "Gérer les styles de musique",
  label28: "Gérer les chansons",
  label29: "Annonces",
  label30: "Télécharger et gérer les médias",
  label31: "Créer, modifier et configurer des horloges",
  label32: "Importer et gérer des annonces",
  label33: "Créer, modifier et configurer des directives",
  label34: "Activité",
  label35: "Créer et modifier les préférences",
  label36: "Groupes d'utilisateurs",
  label37: "Planificateur de listes de lecture",
  label38: "Personnalisation",
  label39: "Planificateur d'annonces",
  label40: "Cloné",
  label41: "Fermer la session",
  label42: "Chargement des données...",
  label43: "Lire plus",
  label44: "Lire moins",
  label45: `Dans cette section, vous pouvez créer des utilisateurs. Vous devez avoir un utilisateur pour chaque emplacement, et chaque utilisateur ne peut être utilisé que sur un site à la fois. Vous pouvez également supprimer ou modifier des utilisateurs (modifier le nom d'utilisateur, le mot de passe, etc.). \ RChaque utilisateur peut être affecté à un ou plusieurs groupes d'utilisateurs, pour faciliter la configuration des utilisateurs en masse. Les groupes d'utilisateurs les créent vous-même dans la section "CRÉER ET MODIFIER LES GROUPES D'UTILISATEURS ". \nVous pouvez également contrôler ce qui est entendu dans chaque salle à partir de cette section. \nPour attribuer des listes de lecture ou des directives publicitaires à un utilisateur, vous devez vous rendre dans la section correspondante ("PLAYLISTS"ou"ANNONCES")`,
  label46: "Utilisateurs embauchés",
  label47: "Utilisateurs actifs",
  label48: "Utilisateurs disponibles",
  label49: "Créer un utilisateur",
  label50: "Écrivez les utilisateurs ou groupes d'utilisateurs à rechercher...",
  label51: "Filtrer par...",
  label52: "Trier par...",
  label53: "Nom",
  label54: "Email",
  label55: "Groupe",
  label56: "Connecté",
  label57: "Débranché",
  label58: "Jamais connecté",
  label59:
    "Janvier,Février,Mars,Avril,Mai,Juin,Juillet,Août,Septembre,Octobre,Novembre,Décembre",
  label60: "Jan,Feb,Mar,Abr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec",
  label61: "Dimanche,Lundi,Mardi,Mercredi,Jeudi,Vendredi,Samedi",
  label62: "Dim,Lun,Mar,Mer,Jeu,Ven,Sam",
  label63: "Di,Lu,Ma,Me,Ju,Ve,Sa",
  label64: "Connecté",
  label65: "Débranché",
  label66: "Utilisateur jamais connecté",
  label67: "Suspendu par le fournisseur de services",
  label68: "Actif",
  label69: "Lecture de la playlist",
  label70: "Playlists lues",
  label71: "Depuis",
  label72: "Groupe(s)",
  label73: "Non attribué à un groupe",
  label74: "Créer \n utilisateur",
  label75:
    "Les noms d'utilisateur doivent inclure le symbole @. \n Si vous ne l'incluez pas, il sera automatiquement ajouté au début du nom d'utilisateur.",
  label76: "Obligatoire",
  label77: "Confirmer mot de passe",
  label78: "Optionnel",
  label79: "La description",
  label80: "Enregistrer les modifications",
  label81: "Enregistrer les modifications...",
  label82: "Créer",
  label83: "Création...",
  label84:
    "Les noms d'utilisateur doivent inclure le symbole @. \n Si vous ne l'incluez pas, il sera automatiquement ajouté au début du nom d'utilisateur.",
  label85: "Oui",
  label86: "Non",
  label87: "Information sur",
  label88: "Utilisateur",
  label89: "Aucune donnée pour le moment",
  label90: "Utilisateur créé",
  label91: "PIN de verrouillage",
  label92: "Aucun PIN ne vous a été attribué",
  label93: "Cinq (5) dernières connexions",
  label94: "Déconnecté",
  label95: "En pause",
  label96: "Hibernation",
  label97: `Vous pouvez cloner les paramètres d'un utilisateur vers un autre ou d'autres utilisateurs, ou vers un ou plusieurs groupes. Vous choisissez l'utilisateur source, puis le ou les utilisateurs cibles, ou le ou les groupes cibles. Sélectionnez les paramètres à cloner et appuyez sur le bouton "CLONE".\n IMPORTANT: les paramètres clonés effaceront les paramètres dont disposent les utilisateurs cibles. Par exemple, si nous clonons les listes de lecture de l'utilisateur, toutes les listes de lecture créées par l'utilisateur cible ou le groupe d'utilisateurs cible seront supprimées.`,
  label98: "Choisissez l'utilisateur source...",
  label99:
    "(Sélectionnez l'utilisateur à partir duquel les paramètres sélectionnés sont clonés ci-dessous)",
  label100: "Sélectionnez les utilisateurs",
  label101: "Sélectionnez des groupes",
  label102: "Tous les utilisateurs",
  label103: "Utilisateur ou utilisateurs cibles",
  label104: "Groupe ou groupes cibles",
  label105:
    "(Sélectionnez ci-dessous le ou les utilisateurs auxquels les paramètres sélectionnés seront clonés)",
  label106:
    "(Sélectionnez le ou les groupes dans lesquels les paramètres sélectionnés ci-dessous seront clonés)",
  label107: "Choisissez le ou les utilisateurs cibles...",
  label108: "Choisissez le ou les groupes de destination...",
  label109: "Paramètres à cloner",
  label110: "Gestionnaire de contenu",
  label111: "(styles utilisateur et modifications de chansons)",
  label112: "Cloner",
  label113: "Clonage...",
  label114: "Personnalisez le logo \n et les données du programme",
  label115:
    "Vous pouvez personnaliser l'application avec votre logo et vos données. Le logo apparaîtra dans la connexion et dans le menu, et vos données apparaîtront dans la section «À propos de cette application».",
  label116: "Sélectionnez une image",
  label117: "Facultatif (remplace le logo de l'application)",
  label118: "Téléchargez une image carrée au format .jpg ou .png.",
  label119: "Télécharger l'image",
  label120: "Restaurer",
  label121: "Les données",
  label122:
    '(Ils seront affichés dans la section "À propos de cette application" du programme)',
  label123: "Nom de l'entreprise ou du commerce",
  label124: "Site Web",
  label125: "Texte",
  label126: "Facultatif (Par exemple, téléphone ou nom du contact)",
  label127: "Sauvegarder",
  label128: "Sauve...",
  label129: "Retirer",
  label130: "Suppression...",
  label131: "Logo \n utilisateur",
  label132: "La personnalisation a été restaurée avec succès.",
  label133:
    "Erreur, un problème est survenu lors de la réinitialisation de la personnalisation.",
  label134: "Voulez-vous réinitialiser la personnalisation?",
  label135:
    "La personnalisation sera supprimée et la valeur par défaut sera restaurée.",
  label136: `Créez les groupes puis, à partir de "CRÉER, CONFIGURER ET CONTRÔLER LES UTILISATEURS (LOCAL)", vous attribuez des utilisateurs au ou aux groupes que vous souhaitez. Un utilisateur peut appartenir à plusieurs groupes. Les groupes d'utilisateurs servent à faciliter la gestion d'un grand nombre d'utilisateurs en attribuant des paramètres tels que des plans de playlist, des directives publicitaires, etc.`,
  label137: "Écrivez le nom du groupe à rechercher...",
  label138: "Créer un groupe",
  label139: "Créer \n un groupe",
  label140: "Éditer",
  label141:
    "Vous pouvez télécharger les rapports des listes de lecture utilisées et des annonces publiées en fonction des dates souhaitées.",
  label142: "Rapports d'annonces",
  label143: "Rapports de playlist",
  label144: "(Seuls les rapports relatifs aux publicités seront affichés)",
  label145: "Type de rapport",
  label146: "Choisissez un type de rapport...",
  label147: "Moyen",
  label148: "Choisissez un support...",
  label149: "Rapport général (Détaillé)",
  label150: "Rapport discriminé par la sélection des médias (Résumé)",
  label151: "Rapport distingué par la sélection des médias (Détaillé)",
  label152: "La date initiale",
  label153: "Obligatoire (à partir de)",
  label154: "Date finale",
  label155: "Obligatoire (jusqu'au)",
  label156:
    "(Seuls les rapports relatifs à l'utilisation des listes de lecture seront affichés)",
  label157: "Télécharger",
  label158: "Téléchargement...",
  label159:
    "Erreur, une erreur s'est produite lors de la génération du rapport.",
  label160: "Le rapport a été généré avec succès.",
  label161:
    "Erreur, le rapport n'a pas été généré, il n'y a aucun enregistrement dans cet intervalle de temps.",
  label162: "Rapport_du_",
  label163: "_(résumé)",
  label164: "_(détaillé)",
  label165: "Nom d'utilisateur",
  label166: "Nom du média",
  label167: "Nom de fichier",
  label168: "Heures de",
  label169: "Heures jusqu'à",
  label170: "Date de lecture",
  label171: "Numéro de reproduction",
  label172: "Plage de",
  label173: "Portée jusqu'à",
  label174:
    "Attribuez une broche de verrouillage, \n Supprimez les chansons dont la langue est inappropriée, \n Définissez l'hibernation",
  label175: "Choisissez un utilisateur...",
  label176: `Vous pouvez configurer différentes listes de lecture ou hibernations pour les différentes plages horaires pour chaque jour de la semaine. Sélectionnez un utilisateur, puis appuyez sur le symbole "+" qui existe à l'extrême droite de chaque jour pour effectuer vos réglages.`,
  label177: "Erreur, vous n'avez pas de compte actif, contactez le fournisseur de services.",
  label178:
    'Dans cette section, vous pouvez télécharger (importer) des annonces et supprimer ou modifier vos annonces déjà téléchargées. Ensuite, dans "CRÉER, MODIFIER ET CONFIGURER DES LIGNES DIRECTRICES", vous pouvez créer vos règles publicitaires afin que les annonces retentissent dans les locaux.',
  label179: "Écrivez le nom de l'annonce à rechercher...",
  label180: "Mettre en ligne",
  label181: "Créer, modifier \n et configurer des directives",
  label182:
    "Vous pouvez ajouter des consignes relatives aux annonces pour vos propres annonces (précédemment téléchargées) afin qu'elles soient diffusées sur site.",
  label183: "Ajouter un motif",
  label184:
    'Créez les groupes puis, à partir de "CRÉER, CONFIGURER ET CONTRÔLER LES UTILISATEURS (LOCAL)", vous attribuez des utilisateurs au ou aux groupes que vous souhaitez. Un utilisateur peut appartenir à plusieurs groupes.',
  label185: "Charge moyenne",
  label186: "Annonceur",
  label187: "Marque ou produit",
  label188: "Utilisateur (local)",
  label189: "Groupe d'utilisateurs",
  label190: "Priorité",
  label191: "nombre de postes",
  label192: "Type de support",
  label193: "Jusqu'à",
  label194: "Emplacements",
  label195: "Versions",
  label196: "Illimité",
  label197: "Créer, modifier \n et configurer des horloges",
  label198:
    "Vous pouvez ajouter des directives relatives aux annonces à partir de vos propres annonces (précédemment importées).",
  label199: "Horloges",
  label200: "Calendrier",
  label201: "Gérer les horloges",
  label202: "Attribuer des horloges au jour normal",
  label203: "Attribuer des horloges à un jour spécial",
  label204: "Calendrier activé",
  label205: "Calendrier désactivé",
  label206: "L'utilisateur a bien été supprimé.",
  label207:
    "Erreur, une erreur s'est produite lors de la suppression de l'utilisateur.",
  label208: "Vous souhaitez supprimer l'utilisateur",
  label209: "L'utilisateur sera supprimé",
  label210:
    "Erreur, le nom d'utilisateur contient des espaces (non autorisés).",
  label211:
    "Erreur, l´utilisateur a besoin d´un minimum de 6 et d´un maximum de 40 caractères.",
  label212:
    "Erreur, le mot de passe nécessite un minimum de 6 et un maximum de 40 caractères.",
  label213: "L'utilisateur a été créé avec succès.",
  label214: "Erreur, le mot de passe et sa confirmation ne sont pas les mêmes.",
  label215: "Erreur, le mot de passe est obligatoire.",
  label216: "Erreur, le nom d'utilisateur est requis.",
  label217: "Modifier l'utilisateur",
  label218:
    "Erreur, le nom d'utilisateur contient le caractère # (non autorisé).",
  label219: "L'utilisateur a réussi la modification.",
  label220:
    "Erreur, un problème est survenu lors de la modification de l'utilisateur.",
  label221: "Le clonage a réussi.",
  label222: "Erreur, un problème est survenu lors du clonage.",
  label223: "Utilisateur d'origine",
  label224:
    "Erreur, une erreur s'est produite lors de l'enregistrement du groupe.",
  label225: "Le groupe a été enregistré avec succès.",
  label226:
    "Erreur, un problème est survenu lors de la modification du groupe.",
  label227: "Le groupe a été modifié avec succès.",
  label228: "Le groupe",
  label229: "a été supprimé avec succès.",
  label230: "Erreur, une erreur s'est produite a supprimé le groupe",
  label231: "Vous souhaitez supprimer le groupe",
  label232: "Le groupe sera supprimé",
  label233: "La personnalisation a été enregistrée avec succès.",
  label234:
    "Erreur, une erreur s'est produite lors de l'enregistrement de la personnalisation.",
  label235: "Afficher les favoris",
  label236: "Afficher ou masquer la barre des favoris (barre inférieure)",
  label237: "Ajouter un code PIN de verrouillage",
  label238: "Modifier le code PIN de verrouillage",
  label239: "Vous n´avez PIN de goupille de verrouillage",
  label240: "Votre PIN goupille de verrouillage est",
  label241: "Ajouter une PIN",
  label242: "Modifier la PIN",
  label243: "Verrouillez maintenant",
  label244:
    "Pour verrouiller l'application, vous avez maintenant besoin d'un code PIN",
  label245: "Lock the app now",
  label246: "Bloquer au lancement de l'application",
  label247:
    "Pour démarrer l'application toujours verrouillé besoin d'un code PIN",
  label248: "L'application démarrera toujours bloquée",
  label249: "Changer la langue",
  label250: "Changer la langue de l'application",
  label251: "Afficher les boutons en jouant",
  label252:
    "Afficher ou masquer les boutons (ne pas programmer, toujours programmer, modifier, ajouter)",
  label253: "Activer la restriction au contenu explicite",
  label254:
    "La programmation de chansons avec un contenu inapproprié ou abusif est restreinte",
  label255: "Mode de mise en veille prolongée",
  label256: "Modifier les paramètres de mise en veille prolongée",
  label257: "Ajouter une configuration de mise en veille prolongée",
  label258: "Ajouter",
  label259: "La langue de l'application a été changée en anglais avec succès.",
  label260:
    "Erreur, quelque chose s'est mal passé lors du changement de la langue de l'application en anglais.",
  label261: "La langue de l'application a été changée en espagnol avec succès.",
  label262:
    "Erreur, quelque chose s'est mal passé lors du changement de la langue de l'application en espagnol.",
  label263: "La langue de l'application a été changée en français avec succès.",
  label264:
    "Erreur, quelque chose s'est mal passé lors du changement de la langue de l'application en français.",
  label265: "La barre des favoris a été activée avec succès.",
  label266: "La barre des favoris a bien été désactivée.",
  label267:
    "Erreur, une erreur s´est produite lors de l´activation de la barre de favoris.",
  label268:
    "Erreur, une erreur s´est produite lorsque vous avez désactivé la barre de favoris.",
  label269: "L'application a été bloquée avec succès.",
  label270: "L'application a été déverrouillée avec succès.",
  label271:
    "Erreur, quelque chose s'est mal passé lors du verrouillage de l'application.",
  label272:
    "Erreur, quelque chose s'est mal passé lors du déverrouillage de l'application.",
  label273: "Verrouillage du lancement de l'application activé avec succès.",
  label274: "Crash au lancement de l'application désactivé avec succès.",
  label275:
    "Erreur, quelque chose s'est mal passé lors de l'activation du verrou au démarrage de l'application.",
  label276:
    "Erreur, quelque chose s'est mal passé lors de la désactivation du verrou au démarrage de l'application.",
  label277: "Boutons de lecture activés.",
  label278: "Boutons de lecture désactivés.",
  label279:
    "Erreur, une erreur s´est produite lors de l´activation des boutons de lecture.",
  label280:
    "Erreur, une erreur s´est produite lors de la désactivation des boutons en cours de lecture.",
  label281:
    "Activé restreint la programmation de chansons avec un contenu inapproprié ou abusif.",
  label282:
    "Off restreint la programmation de chansons au contenu inapproprié ou abusif.",
  label283:
    "Erreur, une erreur s´est produite lors de l´activation de la restriction de contenu explicite.",
  label284:
    "Erreur, une erreur s´est produite lors de la désactivation de la restriction de contenu explicite.",
  label285: "Mode veille prolongée activé.",
  label286: "Mode de mise en veille prolongée désactivé.",
  label287:
    "Erreur, une erreur s´est produite lors de l´activation du mode de mise en veille prolongée.",
  label288:
    "Erreur, une erreur s´est produite lors de la désactivation de la mise en veille prolongée.",
  label289: "Ajouter \n PIN",
  label290: "Modifier \n PIN",
  label291: "PIN actuelle",
  label292: "Nouvelle pin",
  label293: "Obligatoire (le PIN doit être composé de 4 chiffres)",
  label294: "Confirmez votre nouveau code pin",
  label295: "Erreur, seuls les chiffres sont autorisés dans votre code PIN et 0000 n'est pas autorisé",
  label296: "Erreur, la pin doit être composée de 4 chiffres.",
  label297: "Erreur, la confirmation de la pin n´est pas égale à la pin.",
  label298:
    "Erreur, la pin est la même que la précédente, veuillez entrer une nouvelle pin.",
  label299: "La PIN a été enregistrée avec succès.",
  label300: "La PIN a été modifiée avec succès.",
  label301:
    "Erreur, une erreur s´est produite lors de la sauvegarde de la pin.",
  label302:
    "Erreur, quelque chose s´est mal passé lors de la modification de la pin.",
  label303: "Ajouter une configuration \n de mise en veille prolongée",
  label304: "Modifier une configuration \n de mise en veille prolongée",
  label305: "Etat",
  label306: "Calendrier d'hibernation",
  label307: "Dans",
  label308: "Chaque jour",
  label309: "Jours spécifiés",
  label310: "La configuration d´hibernation a été enregistrée avec succès.",
  label311:
    "Erreur, une erreur s´est produite lors de l´enregistrement des paramètres de mise en veille prolongée.",
  label312:
    "La configuration de mise en veille prolongée a été modifiée avec succès.",
  label313:
    "Erreur, une erreur s´est produite lors de la modification des paramètres de mise en veille prolongée.",
  label314: "Planificateur activé avec succès.",
  label315: "Planificateur désactivé avec succès.",
  label316:
    "Erreur, une erreur s´est produite lors de l´activation de l´ordonnanceur.",
  label317:
    "Erreur, quelque chose s´est mal passé, j´ai enregistré la personnalisation..",
  label318: "Planificateur activé",
  label319: "Planificateur désactivé",
  label320: "Ajouter un plan",
  label323: "Mon playlists",
  label324: "Aléatoire",
  label325: "Le plan a été enregistré avec succès.",
  label326: "Erreur, une erreur s´est produite lors de la sauvegarde du plan.",
  label327: "Erreur, le temps depuis est inférieur au temps jusqu´au.",
  label328: "Erreur, un plan existe déjà dans ce fuseau horaire.",
  label329: "Mettre en ligne l'annonce",
  label330: "Le plan a été supprimé avec succès.",
  label331: "Erreur, un problème est survenu lors de la suppression du plan.",
  label332: "Activé aléatoirement avec succès.",
  label333: "Désactivé aléatoirement avec succès.",
  label334:
    "Erreur, une erreur s´est produite lors de l´activation de l´aléatoire.",
  label335: "Erreur, quelque chose s´est mal passé en désactivant le hasard.",
  label336: "Faites glisser \n votre annonce \n ou cliquez \n dans cette zone",
  label337: "Taille",
  label338: "(MP3 uniquement. Taille maximale 5 MB).",
  label339: "Importer une annonce",
  label340: "Importation de l'annonce...",
  label341: "Erreur, (UNIQUEMENT EN MP3).",
  label342:
    "Le nom de l'annonce doit contenir un minimum de 1 et un maximum de 50 caractères.",
  label343:
    "Erreur, quelque chose s'est mal passé lors du téléchargement de l'annonce, vérifiez qu'il s'agit d'un fichier MP3 et que sa taille ne dépasse pas 5 MB.",
  label344: "L'annonce a été téléchargée avec succès.",
  label345: "L'annonce",
  label346:
    "Erreur, un problème est survenu lors de la suppression de l'annonce",
  label347: "Vous souhaitez supprimer l'annonce",
  label348: "L'annonce sera supprimée",
  label349: "Tous les jours",
  label350: "Du lundi au vendredi",
  label351: "Samedi et dimanche",
  label352: "Jour exact",
  label353: "Heure exacte",
  label354: "Intervalle",
  label355: "Chacun",
  label356: "Fréquence",
  label357: "chansons",
  label358: "Limite d´annonces par lot",
  label359: "Début de la ligne directrice",
  label360: "Fin de la ligne directrice",
  label361: "Ajouter une \n ligne directrice",
  label362: "Jours",
  label363: "Programme",
  label364: "Rang du temps",
  label365: "Limite d'annonces par lot",
  label366: "Toutes les x minutes",
  label367: "Toutes les x chansons",
  label368: "Jour ou jours",
  label369: "Heure",
  label370: "Jour",
  label371: "Définir le début",
  label372: "Définir la fin",
  label373: "Erreur, il existe déjà une directive avec ce nom.",
  label374: "Erreur, il y a déjà un modèle dans cet intervalle de temps.",
  label375: "Error, il existe déjà une directive pour cette heure exacte.",
  label376: "Le moyen",
  label377: "Erreur, un problème est survenu lors de la suppression du support",
  label378: "Vous souhaitez supprimer le support",
  label379: "Le support sera supprimé",
  label380: "jusqu'à",
  label381: "Haute",
  label382: "Ordinaire",
  label383:
    "Vous pouvez télécharger n'importe quel type de média (spot, annonce, entre chanson, identifiant radio) \n ou créer votre propre type. Vous choisissez le fichier à charger, vous lui donnez un nom, vous indiquez de quel type de média il s'agit, \n vous attribuez toutes les valeurs, s'il existe des versions vous pouvez les charger et vous indiquez les emplacements où il sonnera.",
  label384: "Définir le début et la fin",
  label385: "Choisir…",
  label386: "Inicio",
  label387: "Fin",
  label388: "Marque ou produit",
  label389: "Entreprise de l'annonceur",
  label390: "Valeurs",
  label391: "Infini",
  label392: "Lieux où \n sera publié",
  label393: "Tous les utilisateurs (locaux)",
  label394: "Sélectionnez des utilisateurs (locaux) ou des groupes",
  label395: "Utilisateurs (locaux)",
  label396: "Groupes",
  label397: `Erreur, une erreur s'est produite lors du chargement du support.`,
  label398: `Le support a été enregistré avec succès.`,
  label399: `Erreur, vous devez sélectionner une priorité.`,
  label400: `Erreur, vous n'avez sélectionné aucun emplacement pour publier.`,
  label401: `Erreur, vous devez sélectionner une marque ou un produit.`,
  label402: `Erreur, vous devez sélectionner un annonceur.`,
  label403: `Erreur, vous devez sélectionner un type de média.`,
  label404: `Erreur, vous devez ajouter un nom de média.`,
  label405: `Filtrer par`,
  label406: `Faites glisser les \n utilisateurs ou groupes ici`,
  label407: `Erreur, une erreur s'est produite lors de l'édition du média.`,
  label408: `Le support a été modifié avec succès.`,
  label409: `Ajouter \n type de média`,
  label410: `Erreur, une erreur s'est produite lors de l'enregistrement du type de support.`,
  label411: `Le type de média a été enregistré avec succès.`,
  label412: `Erreur, le nom du type de support doit contenir de 1 à 50 caractères.`,
  label413: `Ajouter \n annonceur`,
  label414: `Erreur, une erreur s'est produite lors de l'enregistrement de l'annonceur.`,
  label415: `L'annonceur a bien été enregistré.`,
  label416: `Erreur, le nom de l'annonceur doit contenir de 1 à 50 caractères.`,
  label417: `Ajouter \n une marque ou un produit`,
  label418: `Tapez ici pour rechercher ...`,
  label419: `Erreur, une erreur s'est produite lors de l'enregistrement de la marque ou du produit.`,
  label420: `La marque ou le produit a été enregistré avec succès.`,
  label421: `Erreur, le nom de la marque ou du produit doit contenir de 1 à 50 caractères.`,
  label422: `Charger \n versions dans`,
  label423: `La version`,
  label424: `Erreur, un problème est survenu lors de la suppression de la version`,
  label425: `Vous souhaitez supprimer la version`,
  label426: `La version sera supprimée`,
  label427: `Annuler`,
  label428: `Erreur, une erreur s'est produite lors du chargement de la version.`,
  label429: `La version a été chargée avec succès.`,
  label430: `Erreur. La version n'a pas pu être chargée, fermez la fenêtre et réessayez.`,
  label431: `Calendrier activé avec succès.`,
  label432: `Calendrier désactivé avec succès.`,
  label433: `Erreur, une erreur s'est produite lors de l'activation du calendrier.`,
  label434: `Erreur, un problème est survenu lors de la désactivation du calendrier.`,
  label435: `Créer une horloge`,
  label436: `Pour créer une horloge, vous devrez faire glisser le média qui apparaît sur le côté gauche vers la droite. Ensuite, vous pouvez les faire glisser vers le haut ou vers le bas pour les ordonner. Le support "COMMERCIAL" comporte par défaut 16 publicités (spots ou publicités). Les autres n'ont qu'un seul support par défaut. Dans les deux cas, ce chiffre peut être édité en appuyant sur le crayon.`,
  label437: `Recherche de médias`,
  label438: `Faites glisser le \n média ici`,
  label439: `Erreur, le nom de l'horloge doit contenir de 1 à 50 caractères.`,
  label440: `Erreur, une erreur s'est produite lors de l'enregistrement de la montre.`,
  label441: `La montre a été enregistrée avec succès.`,
  label442: `Erreur, vous n'avez ajouté aucun média.`,
  label443: `Gérer \n les horloges`,
  label444: `Modifier ou supprimer, si vous supprimez l'horloge disparaît du calendrier.`,
  label445: `La montre`,
  label446: `Erreur, un problème est survenu lors de la suppression de l'horloge`,
  label447: `Vous souhaitez supprimer l'horloge`,
  label448: `L'horloge sera supprimée`,
  label449: `Quantité`,
  label450: `Blocs de`,
  label451: `Erreur, une erreur s'est produite lors de la modification`,
  label452: `Pour modifier une horloge, vous devez faire glisser le média qui apparaît sur le côté gauche vers la droite. Ensuite, vous pouvez les faire glisser vers le haut ou vers le bas pour les ordonner. Le support "COMMERCIAL" comporte par défaut 16 spots (spots ou publicités). Les autres n'ont qu'un seul support par défaut. Dans les deux cas, ce chiffre peut être édité en appuyant sur le crayon.`,
  label453: `La montre a été modifiée avec succès.`,
  label454: `Attribuez une horloge à chaque fuseau horaire. Vous pouvez quitter les sections sans affecter une horloge, dans ce cas, seule la musique sera entendue (sans média).`,
  label455: `Aucune horloge attribuée`,
  label456: `Erreur, une erreur s'est produite lors de l'enregistrement de la montre un jour normal.`,
  label457: `La montre du jour normal a été enregistrée avec succès.`,
  label458: `Attribuer des horloges à un jour spécial`,
  label459: `Répétitions`,
  label460: `Journée ponctuelle`,
  label461: `Gamme de jours`,
  label462: `Erreur, le nom du jour spécial doit contenir de 1 à 50 caractères.`,
  label463: `La montre du jour spécial a été enregistrée avec succès.`,
  label464: `Erreur, une erreur s'est produite lors de l'enregistrement de la montre un jour spécial.`,
  label465: `Erreur, une horloge est attribuée à ce jour spécifique et sa répétition coïncide ou se trouve dans une autre plage.`,
  label466: `Erreur, une horloge est attribuée dans cette plage.`,
  label467: `Erreur, la date de fin est supérieure à la date de début.`,
  label468: `Modifier ou supprimer \n la structure de la journée`,
  label469: `Modifier ou supprimer, lors de la modification d'une valeur des sélecteurs, elle sera automatiquement modifiée, si vous supprimez le jour où elle disparaîtra du calendrier.`,
  label470: `Cet intervalle a été mis à jour avec succès dans la structure du jour.`,
  label471: `Erreur. une erreur s'est produite lors de la modification de cet intervalle.`,
  label472: `Dernière connexion`,
  label473: `Connecté le `,
  label474: `Finaliser`,
  label475: `Gérer les \n médias`,
  label476: `Gérer les \n agences de publicité`,
  label477: `Gérer des \n marques ou des produits`,
  label478: `Modifier ou supprimer, vous ne pourrez pas supprimer un type de média déjà attribué à un média déjà chargé.`,
  label479: `Modifier ou supprimer, vous ne pourrez pas supprimer une entreprise ou un annonceur déjà affecté à un support déjà chargé.`,
  label480: `Modifier ou supprimer, vous ne pourrez pas supprimer une marque ou un produit déjà attribué à certains médias déjà chargés.`,
  label481: `Type de support`,
  label482: `L'entreprise ou l'annonceur`,
  label483: `La marque ou le produit`,
  label484: `Erreur, un problème est survenu lors de la suppression du type de média`,
  label485: `Erreur, un problème est survenu lors de l'élimination de l'entreprise ou de l'annonceur`,
  label486: `Erreur, un problème est survenu lors de la suppression de la marque ou du produit`,
  label487: `Vous souhaitez supprimer le type de média`,
  label488: `Vous souhaitez supprimer l'entreprise ou l'annonceur`,
  label489: `Vous souhaitez supprimer la marque ou le produit`,
  label490: `Le type de média sera supprimé`,
  label491: `La société ou l'annonceur sera supprimé`,
  label492: `La marque ou le produit sera supprimé`,
  label493: `d'accord`,
  label494: `Avertissement`,
  label495: `Vous ne pouvez pas supprimer ce type de support, puisqu'il est affecté à un ou plusieurs supports déjà chargés, si vous souhaitez le supprimer, vous devez d'abord supprimer le support qui lui est associé.`,
  label496: `Vous ne pouvez pas supprimer cette société ou cet annonceur, car il est affecté à un ou plusieurs médias déjà chargés, si vous souhaitez le supprimer, vous devez d'abord supprimer le média qui lui est associé.`,
  label497: `Vous ne pouvez pas supprimer cette marque ou ce produit, car il est attribué à un ou plusieurs supports déjà chargés, si vous souhaitez le supprimer, vous devez d'abord supprimer le support qui lui est associé.`,
  label498: `Vous avez dépassé la limite de médias à charger, disponibles pour votre abonnement, veuillez nous contacter pour prolonger les limitations de votre abonnement.`,
  label499: `Vous avez dépassé la limite des versions disponibles pour votre abonnement, veuillez nous contacter pour étendre les limitations de votre abonnement.`,
  label500: `Vous avez dépassé la limite de taille d'annonce disponible pour votre abonnement, veuillez nous contacter pour étendre les limites de votre abonnement.`,
  label501: `Vous avez dépassé la limite du type de média que vous pouvez créer, disponible pour votre abonnement, veuillez nous contacter pour étendre les limitations de votre abonnement.`,
  label502: `Actuellement, votre limite de création de type de média est: `,
  label503: `Actuellement, votre limite de téléchargement multimédia est: `,
  label504: `Actuellement, votre limite de téléchargement de version est: `,
  label505: `Actuellement, votre taille limite par annonce est: `,
  label506: `Vous souhaitez supprimer le motif`,
  label507: `Le motif sera supprimé`,
  label508: `Ajouter / enlever`,
  label509: `Nombre d'annonces chargées`,
  label510: `Ajouter ou supprimer des annonces`,
  label511: `Ajouter ou supprimer des publicités, vous devez faire glisser les publicités qui apparaissent sur le côté gauche vers la droite. Vous pouvez ensuite les faire glisser vers le haut ou vers le bas pour les trier.`,
  label512: `Annonces pour la ligne directrice`,
  label513: `Recherche d'annonces`,
  label514: `Faites glisser les \n annonces ici`,
  label515: `Erreur, une erreur s'est produite lors de l'enregistrement du motif.`,
  label516: `Le modèle a été enregistré avec succès.`,
  label517: `Erreur, une erreur s'est produite lors de l'édition du motif.`,
  label518: `La directive a été modifiée avec succès.`,
  label519: `Rapport discriminé par la sélection de média déjà supprimé (Résumé)`,
  label520: `Rapport distingué par la sélection de média déjà supprimé (détaillé)`,
  label521: `À moitié déjà effacé`,
  label522: `Mes Styles`,
  label523: `Vous pouvez créer vos propres styles musicaux, puis aller dans "GÉRER LES CHANSONS" et assigner les chansons que vous voulez faire appartenir à tel ou tel style.`,
  label524: `Écrivez le nom du style à rechercher ...`,
  label525: `Le style`,
  label526: `Erreur, une erreur s'est produite lors de la suppression du style`,
  label527: `Vous souhaitez supprimer le style`,
  label528: `Le style sera supprimé`,
  label529: `Créer \n du style`,
  label530: `Créez du style`,
  label531: `Erreur, une erreur s'est produite lors de l'enregistrement du style.`,
  label532: `Le style a été enregistré avec succès.`,
  label533: `Erreur, le nom du style doit contenir de 1 à 50 caractères.`,
  label534: `Erreur, un problème est survenu lors de la modification du style.`,
  label535: `Le style a été modifié avec succès.`,
  label536: `Modifier \n le style`,
  label537: `Gestionnaire de chansons`,
  label538: `Vous pouvez changer le style d'une chanson en un style créé par l'utilisateur, vous pouvez changer le nom de la chanson, l'indiquer comme explicite (langage inapproprié) ou la marquer comme favorite ou non souhaitée. Vous pouvez également l'affecter à une liste de lecture manuelle.`,
  label539: `Écrivez ici le nom de la chanson ou de l'artiste à rechercher...`,
  label540: `Chercher`,
  label541: `Montrer`,
  label542: `Toutes`,
  label543: `Uniquement édité`,
  label544: `Favoris`,
  label545: `Non souhaité`,
  label546: `Modifier \n la chanson`,
  label547: `Prédéterminé`,
  label548: `Erreur, les champs nom et mot de passe sont obligatoires.`,
  label549: `Nom d'origine`,
  label550: `Style musical`,
  label551: `Humour`,
  label552: `Préférence`,
  label553: `Chanson explicite`,
  label554: `(contenu inapproprié ou abusif)`,
  label555: `Erreur, un problème est survenu lors de l'édition du morceau.`,
  label556: `La chanson a été éditée avec succès.`,
  label557: `Erreur, le nom du morceau doit contenir de 1 à 250 caractères.`,
  label558: `Voulez-vous supprimer cette chanson comme favorite`,
  label559: `Vous souhaitez ajouter cette chanson à vos favoris`,
  label560: `il ne sera plus toujours inclus normalement.`,
  label561: `Il sera toujours inclus dans les listes de lecture associées au style de cette chanson.`,
  label562: `Erreur, un problème est survenu lorsque j'ai annulé cette chanson comme favorite.`,
  label563: `Erreur, quelque chose s'est mal passé lors de la mise en favori de cette chanson.`,
  label564: `La chanson a été annulée avec succès comme favorite.`,
  label565: `La chanson a été mise en signet avec succès.`,
  label566: `Vous souhaitez supprimer cette chanson comme spam`,
  label567: `Vous souhaitez marquer cette chanson comme spam`,
  label568: `il sera à nouveau entendu comme d'habitude.`,
  label569: `il ne sera plus entendu.`,
  label570: `Erreur, une erreur s'est produite lors de la suppression du marquage de cette chanson comme indésirable.`,
  label571: `Erreur, une erreur s'est produite lors du marquage de cette chanson comme spam.`,
  label572: `La chanson a été supprimée avec succès comme spam.`,
  label573: `Le morceau a été marqué avec succès comme spam.`,
  label574: `Choisissez la playlist ...`,
  label575: `Pays`,
  label576: `Ville`,
  label577: `Province`,
  label578: `Erreur, le nom est déjà utilisé, veuillez en choisir un autre.`,
  label579: `Vous pouvez créer vos propres playlist, de deux manières différentes. Automatique: vous choisissez les styles et le programme choisit les chansons. Manuel: vous choisissez les chansons une par une. Vous pouvez également supprimer des listes de lecture ou modifier celles existantes.`,
  label580: `La playlist`,
  label581: `Erreur, un problème est survenu lors de la suppression des playlist`,
  label582: `Vous souhaitez supprimer la playlist`,
  label583: `La playlist sera supprimée`,
  label584: `Manuel de la playlist`,
  label585: `Playlist automatique`,
  label586: `Écrivez ici le nom de la playlist à rechercher...`,
  label587: `Créer une playlist`,
  label588: `Annonces indicatives`,
  label589: `Créer \n une playlist`,
  label590: `Choisis une option.`,
  label591: `Automatique`,
  label592: `Manuel`,
  label593: `vous choisissez les styles et le programme choisit les chansons.`,
  label594: `vous choisissez les chansons une par une.`,
  label595: `Sélectionnez le type de playlist à créer...`,
  label596: `Nom de la nouvelle playlist (obligatoire)...`,
  label597: `Description (facultatif)...`,
  label598: `Suivant`,
  label599: `Faites glisser les styles souhaités de gauche à droite. Vous pouvez faire glisser un style plusieurs fois pour que \n plus de chansons de ce style soient programmées. Ensuite, dans le panneau de droite, vous pouvez faire glisser les styles vers le haut \n et vers le bas pour trier. \n Vous pouvez également modifier les paramètres de style afin de pouvoir programmer des morceaux plus calmes ou plus déplacés, etc.`,
  label600: `Écrivez ici le nom du style à rechercher...`,
  label601: `Recherche de styles musicaux`,
  label602: `Styles`,
  label603: `Mes styles`,
  label604: `Durée (en heures)`,
  label605: `Restreindre explicite`,
  label606: `Faites glisser les styles ici`,
  label607: `Erreur, une erreur s'est produite lors de la création de la playlist automatique.`,
  label608: `La playlist automatique a été créée avec succès.`,
  label609: `Erreur, aucun style n'est sélectionné pour créer la playlist.`,
  label610: `Modifier \n des playlist automatiques`,
  label611: `Faites glisser les styles souhaités de gauche à droite. Vous pouvez faire glisser un style plusieurs fois pour que \n plus de chansons de ce style soient programmées. Ensuite, dans le panneau de droite, vous pouvez faire glisser les styles vers le haut \n et vers le bas pour trier. \n Vous pouvez également modifier les paramètres de style afin de pouvoir programmer des chansons plus silencieuses ou plus déplacées, etc.`,
  label612: `Erreur, une erreur s'est produite lors de la modification de la playlist automatique.`,
  label613: `La playlist automatique a été modifiée avec succès.`,
  label614: `Erreur, le nom des playlist doit contenir au moins un caractère.`,
  label615: `Modifier`,
  label616: `Seuls les succès`,
  label617: `Succès et recommandé`,
  label618: `Succès et recommandé et varié`,
  label619: `Recherchez les chansons de votre choix, par nom ou par nom d'artiste. Puis \n faites glisser chaque chanson de gauche à droite. Dans le panneau de droite, vous pouvez faire glisser \n vers le haut et vers le bas les morceaux pour les trier.`,
  label620: `Recherche de chanson`,
  label621: `Faites glisser les chansons ici`,
  label622: `Erreur, une erreur s'est produite lors de la création de la playlist manuelle.`,
  label623: `La playlist manuelle a été créée avec succès.`,
  label624: `Erreur, la limite de chansons par playlist est`,
  label625: `Modifier \n la playlist manuelle`,
  label626: `Erreur, une erreur s'est produite lors de la modification de la playlist manuelle.`,
  label627: `Les playlist manuelles ont été modifiées avec succès.`,
  label629: `Nombre de chansons`,
  label630: `(Administrateur) -`,
  label631: `(Utilisateur) -`,
  label632: `aux playlist manuelles`,
  label633: `Ils ne peuvent être ajoutés qu'aux playlist manuelles que vous avez créées.`,
  label634: `Playlists de destination`,
  label635: `Ajouter...`,
  label636: `La chanson a été ajoutée avec succès.`,
  label637: `Erreur, une erreur s'est produite lors de l'ajout de la chanson.`,
  label638: `Choisissez un groupe...`,
  label639: `Type de connexion`,
  label640: `Choisissez un type de connexion...`,
  label641: `Recherche ponctuelle`,
  label642: `Résultats: `,
  label643: `Appliquer le filtre`,
  label644: `Écrivez ici le nom d'utilisateur à rechercher...`,
  label645: `Chansons non explicites`,
  label646: `Chansons explicites`,
  label647: `Choisir un style...`,
  label648: `Choisissez un style d'utilisateur...`,
  label649: `Choisissez une humeur...`,
  label650: `Contenu explicite`,
  label651: `Choisissez le type de contenu...`,
  label652: `Critères utilisateur`,
  label653: `Styles d'utilisateur`,
  label654: `Erreur de fichier audio.`,
  label655: `Mot de passe actuel`,
  label656: `Pays`,
  label657: `Province`,
  label658: `Ville`,
  label659: `Hibernation`,
  label660: `Erreur, l'heure de départ doit être supérieure à l'heure de destination.`,
  label661: `Erreur, la différence entre les heures doit être d'au moins 5 minutes.`,
  label662: `Erreur, vous devez sélectionner au moins un jour.`,
  label663: `Erreur, il y a (`,
  label664: `) plan(s) interférant avec la plage horaire que vous souhaitez créer le(s) jour(s) sélectionné(s).`,
  label665: `Tous les plans de la journée ont été supprimés, `,
  label666: `Erreur, quelque chose s'est mal passé lors de la suppression de tous les plans de la journée, `,
  label667: `vous voulez supprimer tous les plans de la journée?`,
  label668: `Tous les plans de cette journée seront supprimés, `,
  label668: `Tous les plans de cette journée seront supprimés, `,
  label669: `Volume global de l'application`,
  label670: `L'échelle de volume de l'application va de 0 à 100`,
  label671: `Le volume de l'application a été mis à jour avec succès.`,
  label672: `Erreur lors de la mise à jour du volume de l'application.`,
  label673: `Désactiver l'application`,
  label674: `Réactiver l'application`,
  label675: `Couper le son de l'application`,
  label676: `Activer le son de l'application`,
  label677: "L'application a été mise en sourdine avec succès.",
  label678: "L'application a été réactivée avec succès.",
  label679:
    "Erreur, quelque chose s'est mal passé lors de la désactivation de l'application.",
  label680:
    "Erreur, quelque chose s'est mal passé lors de la réactivation de l'application.",
  label681: "Effacer tout",
  label682:
    "Les personnalisations de vos utilisateurs ont été restaurées avec succès.",
  label683:
    "Erreur, une erreur s'est produite lors de la réinitialisation de la personnalisation de vos utilisateurs.",
  label684:
    "Vous souhaitez réinitialiser la personnalisation de vos utilisateurs ?",
  label685:
    "La personnalisation sera supprimée de vos utilisateurs et la valeur par défaut sera restaurée pour chacun d'eux.",
  label686: "Sans descriptif",
  root: {
    1: "GOD",
    2: "SUPER-RACINE",
    3: "RACINE",
    4: "SUPER-ADMINISTRATEUR",
    5: "ADMINISTRATEUR",
    6: "SUPERVISEUR",
    7: "GÉRÉ-UTILISATEUR",
    8: "UTILISATEUR-INDIVIDUEL",
  },
  label687: "Compte",
  label688: "Gestion de compte",
  label689: "Changer le mot de passe",
  label690:
    "Modifier les données du compte (Raison sociale, adresse, CIF/NIF, etc.)",
  label691:
    "Télécharger les factures ou mettre à jour les détails de la carte de débit/crédit",
  label692: "Modifier ou annuler l'abonnement",
  label693: "Changer le mot de passe du compte courant.",
  label694: "nouveau mot de passe",
  label695: "Le mot de passe a été changé avec succès.",
  label696:
    "Erreur, une erreur s'est produite lors de la modification du mot de passe dans un membre.",
  label697:
    "Erreur, quelque chose s'est mal passé lors de la modification du mot de passe.",
  label698: `Installer avec les autorisations d'administrateur. Dans Windows 10, entrez simplement le nom d'utilisateur et le mot de passe de l'administrateur lors de l'installation du programme. Sous Windows 7, le compte dans lequel l'installation est effectuée doit être un administrateur. Si vous souhaitez installer dans un compte standard (non administrateur), vous devez transformer ledit utilisateur en administrateur, installer le programme puis le retransformer en utilisateur standard. Dans tous les cas, vous devez vérifier qu'aucun programme ne bloque l'accès de l'application à Internet (Antivirus, Pare-feu, etc.).`,
  label699: `Pour installer le programme sur un ordinateur Mac OS, procédez comme suit : Accédez à Préférences Système Sécurité et confidentialité. Cliquez sur le cadenas en bas à gauche et entrez votre mot de passe utilisateur pour déverrouiller votre Mac.Cochez la case "Partout" dans la zone "Autoriser les applications tierces".`,
  label700: `Nous vous recommandons d'installer l'application et d'activer les mises à jour automatiques, directement depuis le Google Play Store, en cas d'installation en dehors du magasin, les mises à jour de l'application seront gérées par vous, en vérifiant la dernière version de l'application publiée sur cette page.`,
  label701: "Téléchargements",
  label702: "Important",
  label703: `Cette zone est publique, aucun mot de passe n'est nécessaire pour y accéder, j'ai copié le lien de cette page ci-dessous et le partage avec tous vos utilisateurs qui ont besoin de télécharger le programme.`,
  label704: `Dernière version de l'application`,
  label705: `Aller`,
  label706: `Instructions`,
  label707: `Les pré-requis techniques`,
  label708: `Système d'exploitation Windows 7 ou supérieur (Windows 10 recommandé), l'application ne peut pas être utilisée dans la version Windows N, car elle ne dispose pas de "Media Feature Pack", consultez dans ce cas la zone d'assistance.`,
  label709: `16 Go d'espace disque disponible (32 Go recommandés ou plus)`,
  label710: `4 Go de RAM ou plus`,
  label711: `Chartre de son`,
  label712: `Connexion Internet`,
  label713: `Architecture de processeur ARM ou ARM64`,
  label714: `2 Go de RAM (4 Go recommandés ou plus)`,
  label715: `Copier l'URL de la zone de téléchargement`,
  label716: `Copier l'URL du lecteur Web`,
  label717: `Support`,
  label718: `Pour obtenir des <font color=#FFFFFFF'>instructions</font> sur l'utilisation de la plate-forme et les <font color=#FFFFFFF'>questions fréquemment posées</font>, veuillez`,
  label719: `Aide et questions fréquemment posées`,
  label720: `Plus d'options`,
  label721: `Plus d'informations`,
  label722: "Certificat",
  label723: `URL de la zone de téléchargement, copiée avec succès.`,
  label724: "URL du lecteur Web, copiée avec succès.",
  label725: "Actualiser, annuler les modifications",
  label726: "Réinitialisez la personnalisation de tous vos utilisateurs",
  label727: "Télécharger le certificat",
  label728: "certificat_freedom",
  label729: "Information",
  label730:
    "Mac OS Catalina (10.15.7) ou supérieur (compatible également avec les versions inférieures à Catalina mais avec une mise à jour manuelle du programme)",
  label731: "Musique",
  label732: "Voulez-vous vous déconnecter?",
  label733: "Contact par ticket",
  label734: "Contact par WhatsApp",
  label735: `Utilisateur suspendu par l'administrateur`,
  label736: "Licencier",
  label737: "Activer",
  label738: `Suspendu par l'administrateur`,
  label739: `L'utilisateur a été activé avec succès`,
  label740: "L'utilisateur a été suspendu avec succès",
  label741:
    "Erreur, quelque chose s'est mal passé lors de l'activation de l'utilisateur",
  label742:
    "Erreur, quelque chose s'est mal passé lors de la suspension de l'utilisateur",
  label743: "Listes de lecture préférées",
  label744: "Suspendre à",
  label745: "Activer pour",
  label746: "Voulez-vous suspendre temporairement cet utilisateur ?",
  label747: "Voulez-vous activer cet utilisateur ?",
  label748: "Autorisations des app",
  label749: "L'autorisation a été activée avec succès",
  label750: "L'autorisation a été désactivée avec succès",
  label751: "Erreur lors de l'activation de l'autorisation",
  label752: "Erreur lors de la désactivation de l'autorisation",
  label753: "Demander un code PIN activé",
  label754: "Demander un code PIN désactivé",
  label755: "Erreur lors de l'activation de la demande de code PIN",
  label756: "Erreur lors de la désactivation de l'invite de code PIN",
  label757: "Demander un code PIN, activé",
  label758: "Demander un code PIN, Désactivé",
  label759: "Ajouter",
  label760: "La playlist a été ajoutée avec succès aux favoris",
  label761:
    "Elle n'a pas été ajoutée car la playlist existe déjà dans les favoris",
  label762:
    "Erreur, quelque chose s'est mal passé lors de l'ajout de la playlist aux favoris",
  label763: "Ajouter...",
  label764: "Ajouter aux listes de lecture préférées",
  label765: "Alias",
  label766:
    "Erreur, le mot de passe contient des espaces vides (non autorisés).",
  label767: "Erreur, vous devez confirmer votre mot de passe.",
  label768: "Autorisations d'application",
  label769: "Licences",
  label770: "Utilisateurs suspendus",
  label771:
    "La langue de l'application a été modifiée avec succès en portugais.",
  label772:
    "Erreur, quelque chose s'est mal passé lors du changement de langue de l'application en portugais.",
  label773: "Interprètes bloqués",
  label774: "Ajouter aux artistes bloqués",
  label775: "Écrivez le nom de l'interprète à rechercher...",
  label776: "L'interprète a été ajouté avec succès aux interprètes bloqués",
  label777:
    "L'interprète n'a pas été ajouté, il existe déjà dans les interprètes bloqués",
  label778:
    "Erreur, quelque chose s'est mal passé lors de l'ajout de l'interprète aux interprètes bloqués",
  label779: "L'interprète",
  label780: "a été supprimé avec succès de l'interprète bloqué.",
  label781:
    "Erreur, quelque chose s'est mal passé lors de la suppression de l'interprète de cette liste",
  label782: "Vous souhaitez supprimer les interprètes bloqués,",
  label783: "L'interprète sera supprimé",
  label784: "d'interprète bloqué.",
  label785: "Réglage",
  label786: "Abonnement invalide, contacter le fournisseur de services",
  label787: `Erreur, vous avez atteint la limite de comptes souscrits.`,
  label788: `Erreur, quelque chose s'est mal passé lors de la création de l'utilisateur.`,
  label789: "Vous n'avez pas la permission de créer, modifier ou supprimer des utilisateurs.",
  label790: "Vous n'avez pas la permission d'accéder ou de voir les licences.",
  label791: "Vous n'avez pas la permission de réinitialiser la personnalisation pour tous les utilisateurs.",
  label792: "Vous n'avez pas la permission de voir la playlist favorite d'un utilisateur.",
  label793: "Vous n'avez pas la permission d'ajouter ou de supprimer la playlist favorite d'un utilisateur.",
  label794: "Vous n'avez pas la permission de voir le planificateur de playlists d'un utilisateur.",
  label795: "Vous n'avez pas la permission d'ajouter, de modifier ou de supprimer des playlists dans le planificateur de playlists d'un utilisateur.",
  label796: "Vous n'avez pas la permission de voir le planificateur de publicités d'un utilisateur.",
  label797: "Vous n'avez pas la permission d'ajouter, de modifier ou de supprimer des publicités dans le planificateur de publicités d'un utilisateur.",
  label798: "Vous n'avez pas la permission de cloner des utilisateurs.",
  label799: "Vous n'avez pas la permission d'accéder aux préférences de l'utilisateur.",
  label800: "Vous n'avez pas la permission d'accéder à la personnalisation de l'utilisateur.",
  label801: "Vous n'avez pas la permission d'accéder aux permissions des applications de l'utilisateur.",
  label802: "Vous n'avez pas la permission de suspendre un utilisateur.",
  label803: "Vous n'avez pas la permission de voir les playlists de l'administrateur.",
  label804: "Vous n'avez pas la permission de créer, modifier ou supprimer les playlists de l'administrateur.",
  label805: "Vous n'avez pas la permission de voir les playlists favorites de l'administrateur.",
  label806: "Vous n'avez pas la permission d'ajouter ou de supprimer les playlists favorites de l'administrateur.",
  label807: "Vous n'avez pas la permission de voir les styles de l'administrateur.",
  label808: "Vous n'avez pas la permission de créer, modifier ou supprimer les styles de l'administrateur.",
  label809: "Vous n'avez pas la permission d'accéder au gestionnaire de chansons.",
  label810: "Vous n'avez pas la permission de voir les artistes bloqués.",
  label811: "Vous n'avez pas la permission d'ajouter ou de supprimer des artistes bloqués.",
  label812: "Vous n'avez pas la permission de voir les publicités téléchargées.",
  label813: "Vous n'avez pas la permission de télécharger ou de supprimer des publicités.",
  label814: "Vous n'avez pas la permission d'accéder au support et aux téléchargements.",
  label815: "Modifier la sélection",
  label816: "Tout sélectionner",
  label817: "Superviseurs",
  label818: "Créer ou modifier des superviseurs et limiter leurs actions.",
  label819: "Les permissions ont été enregistrées avec succès.",
  label820: "Erreur, quelque chose s'est mal passé lors de l'enregistrement des permissions.",
  label821: "Superviseur suspendu par l'administrateur.",
  label822: "Le style a été changé pour les chansons sélectionnées.",
  label823: "Les chansons sélectionnées ont été changées pour le style par défaut.",
  label824: "Les chansons sélectionnées ont été ajoutées aux playlists sélectionnées.",
  label825: "Les chansons sélectionnées ont été marquées comme explicites avec succès.",
  label826: "Les chansons sélectionnées ont été démarquées comme explicites avec succès.",
  label827: "Les chansons sélectionnées ont été marquées comme 'aimées' avec succès.",
  label828: "Les chansons sélectionnées ont été démarquées comme 'aimées' avec succès.",
  label829: "Les chansons sélectionnées ont été marquées comme 'non désirées' avec succès.",
  label830: "Les modifications ont été supprimées pour les chansons sélectionnées.",
  label831: "Erreur, quelque chose s'est mal passé lors du changement de style des chansons.",
  label832: "Erreur, quelque chose s'est mal passé lors du changement des chansons pour le style par défaut.",
  label833: "Erreur, quelque chose s'est mal passé lors de l'ajout des chansons aux playlists.",
  label834: "Erreur, quelque chose s'est mal passé lors du marquage des chansons comme explicites.",
  label835: "Erreur, quelque chose s'est mal passé lors du démarquage des chansons comme explicites.",
  label836: "Erreur, quelque chose s'est mal passé lors du marquage des chansons comme 'aimées'.",
  label837: "Erreur, quelque chose s'est mal passé lors du démarquage des chansons comme 'aimées'.",
  label838: "Erreur, quelque chose s'est mal passé lors du marquage des chansons comme 'non désirées'.",
  label839: "Erreur, quelque chose s'est mal passé lors de la suppression des modifications des chansons.",
  label840: "Choisissez une action...",
  label841: "Application en cours...",
  label842: "Appliquer",
  label843: "Changer pour un style utilisateur",
  label844: "Changer pour le style par défaut",
  label845: "Ajouter à une playlist manuelle",
  label846: "Marquer comme explicite",
  label847: "Démarquer comme explicite",
  label848: "Marquer comme aimé",
  label849: "Démarquer comme aimé",
  label850: "Marquer comme non désiré",
  label851: "Supprimer les modifications",
  label852: "Chansons sélectionnées",
  label853: "Action à entreprendre",
  label854: "Permissions",
  label855: "Erreur, quelque chose s'est mal passé lors de la modification du superviseur",
  label856: "Modifier le superviseur",
  label857: "Observation",
  label858: "Erreur, le champ du nom d'utilisateur est obligatoire",
  label859: "Erreur, vous avez atteint la limite de superviseurs autorisés.",
  label860: "Erreur, quelque chose s'est mal passé lors de la création du superviseur.",
  label861: "Créer un superviseur",
  label862: "Permissions pour l'utilisation du panneau de contrôle, appuyez sur le bouton de sauvegarde pour appliquer les changements.",
  label863: "La limite de superviseurs que vous pouvez créer est de 5.",
  label864: "Tapez le nom du superviseur à rechercher...",
  label865: "Erreur, quelque chose s'est mal passé lors de la suppression du superviseur",
  label866: "Voulez-vous supprimer le superviseur",
  label867: "Le superviseur sera supprimé",
  label868: "Voulez-vous suspendre temporairement ce superviseur?",
  label869: "Voulez-vous activer ce superviseur?",
  label870: `Préparation de l'annonce...`,
  label871: `Générer avec IA`,
  label872: `Générer une annonce avec IA`,
  label873: `Sergio (Espagnol - Espagne)`,
  label874: `Lucia (Espagnol - Espagne)`,
  label875: `Andrés (Espagnol - Mexique)`,
  label876: `Mia (Espagnol - Mexique)`,
  label877: `Kevin (Anglais)`,
  label878: `Ruth (Anglais)`,
  label879: `Le texte de l'annonce doit contenir entre 5 et 350 caractères.`,
  label880: `Erreur, l'annonce n'a pas été générée en raison de problèmes avec les paramètres.`,
  label881: `Erreur, ce compte ne dispose pas de crédits actifs ou n'a pas accès à cette fonction.`,
  label882: `Erreur, l'annonce n'a pas été générée en raison d'un manque de crédits. Contactez-nous ou attendez un mois.`,
  label883: `Erreur, l'annonce n'a pas été générée, erreur du service IA.`,
  label884: `Erreur, l'annonce n'a pas été générée.`,
  label885: `Génération de l'annonce...`,
  label886: `Générer`,
  label887: `Crédits disponibles`,
  label888: `Vos crédits se renouvellent mensuellement.`,
  label889: `Veuillez noter que chaque génération vous retire 1 crédit, que vous sauvegardiez ou non l'annonce générée.`,
  label890: `Si vous avez besoin de plus de crédits, Contactez-nous.`,
  label891: `Texte`,
  label892: `Voix`,
  label893: `Choisir une voix...`,
  label894: `Erreur, le nom de l'annonce existe déjà, veuillez en choisir un autre.`
};

export default fr_FR;
