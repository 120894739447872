import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
  Text,
  Columns,
  Column,
  Loader,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import { Ad } from "../../models";
import moment from "moment";
import { Select } from "../../Components/Select";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { Textarea } from "../Textarea";

const base64ToUint8Array = (base64) => {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

@observer
class AdsCreateWithAiModal extends Component {
  @observable ad;

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isGenerate: false,
      messageError: null,
      processing: false,
      adIA: {
        text: "",
        index: -1,
        language: "",
        gender: "",
        characters: 0,
      },
    };

    this.ad = this.newAd();
    this.handleTogglePlay = this.handleTogglePlay.bind(this);
    this.handleStartPlayingClick = this.handleStartPlayingClick.bind(this);
  }

  newAd = () => {
    const adStore = this.props.store.ads;
    const attrs = {
      name: "",
      size: "",
      origin_type: 1,
      ad_path: null,
      deleted: 0,
    };
    return new Ad(attrs, adStore);
  };

  reload = () => {
    this.props.store.loadUserLimitations();
  };

  handleStartPlayingClick() {
    var that = this;
    that.props.store.ui.startPlayingAds(this.ad, 1, false);
  }

  handleTogglePlay() {
    if (this.ad.ad_path) {
      if (!this.props.store.ui.isPlaying) {
        this.props.store.ui.play();
      } else {
        this.props.store.ui.pause();
      }
    }
  }

  getVoceOptions = () => {
    const options = [
      {
        value: 1,
        language: "es-ES",
        gender: "male",
        label: this.props.store.language.label873
      },
      {
        value: 2,
        language: "es-ES",
        gender: "female",
        label: this.props.store.language.label874
      },
      {
        value: 3,
        language: "es-MX",
        gender: "male",
        label: this.props.store.language.label875
      },
      {
        value: 4,
        language: "es-MX",
        gender: "female",
        label: this.props.store.language.label876
      },
      { 
        value: 5, 
        language: "en-US", 
        gender: "male", 
        label: this.props.store.language.label877
      },
      { value: 6, 
        language: "en-US", 
        gender: "female", 
        label: this.props.store.language.label878
      },
    ];
    return options;
  };

  processFile = async (audio_data) => {
    const user = this.props.store.loggedInUser;
    let ffmpeg = new FFmpeg();
    this.ad.ad_path = null

    try {
      this.setState({ processing: true });

      await ffmpeg.load();

      // Convertir Base64 a ArrayBuffer usando fetch API
      //const response = await fetch(`data:audio/mp3;base64,${audio_data}`);
      //const arrayBuffer = await response.arrayBuffer();
      //const uint8Array = new Uint8Array(arrayBuffer);

      let uint8Array = base64ToUint8Array(audio_data);

      // Escribir el archivo en FFmpeg
      await ffmpeg.writeFile("input.mp3", uint8Array);

      await ffmpeg.exec([
        "-i",
        "input.mp3",
        "-af",
        "loudnorm=I=-16:TP=-2:LRA=7",
        "-b:a",
        "192k", // Bitrate a 192 kbps
        "output.mp3",
      ]);

      let data = await ffmpeg.readFile("output.mp3");

      /*const mp3File = new File([data.buffer], "output.mp3", {
        type: "audio/mp3",
      });*/

      // Crear Blob antes de File para compatibilidad total
      let mp3Blob = new Blob([data.buffer], { type: "audio/mp3" });
      let mp3File = new File([mp3Blob], "output.mp3", { type: "audio/mp3" });

      let momenStr = moment(new Date()).format("YYYYMMDDHHmmss");
      this.ad.ad_path = new File([mp3File], `${user.id}-AD-${momenStr}.mp3`, {
        type: mp3File.type,
      });
      this.ad.size = (mp3File.size / 1048576).toFixed(2);

      this.handleStartPlayingClick();

      // Liberar memoria en FFmpeg
      await ffmpeg.deleteFile("input.mp3");
      await ffmpeg.deleteFile("output.mp3");

      // Liberar referencias de archivos grandes
      mp3Blob = null;
      mp3File = null;
      uint8Array = null;
      data = null;

      // Forzar recolección de basura si es posible
      if (typeof window.gc === "function") {
        window.gc();
      } 

    } catch (error) {
      console.error("Error al procesar el archivo:", error);
    } finally {
      this.setState({ processing: false, isGenerate: false });

      // Liberar FFmpeg (importante para evitar consumo excesivo de memoria)
      await ffmpeg.terminate();
    }
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label870}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  };

  validateLength = (value, minLen, maxLen) => {
    if (value.length >= minLen && value.length <= maxLen) {
      return true;
    } else {
      return false;
    }
  };

  handleChange = (sender, value, name, validation) => {
    if (name === "name") {
      if (this.validateLength(value, 1, 50)) {
        this.setState({ messageError: null });
        this.ad.name = value;
      } else {
        this.ad.name = "";
        this.setState({ messageError: this.props.store.language.label342 });
      }
    }
    if (name === "text") {
      const { adIA } = this.state;
      let _adIA = adIA;
      if (this.validateLength(value, 5, 350)) {
        _adIA.text = value;
        _adIA.characters = value.length;
        this.setState({ messageError: null, adIA: _adIA });
      } else {
        this.setState({
          messageError:
            this.props.store.language.label879
        });
      }
    }
  };

  handleChangeSelect = (value, sender) => {
    const { adIA } = this.state;
    let _adIA = adIA;
    switch (sender.name) {
      case "voce":
        _adIA.index = value.value;
        _adIA.language = value.language;
        _adIA.gender = value.gender;
        this.setState({ adIA: _adIA });
        break;
      default:
        break;
    }
  };

  handleClose = () => {
    this.props.store.ui.destroy();
    this.props.onClose && this.props.onClose();
  };

  getMessager(value) {
    const messager = {
      1: this.props.store.language.label880,
      2: this.props.store.language.label881,
      3: this.props.store.language.label882,
      4: this.props.store.language.label883,
      5: '',
      6: this.props.store.language.label884
    };
    return messager[value];
  };

  handleGenerate = () => {
    const { toastManager } = this.props;
    const { adIA } = this.state;
    this.setState({ isGenerate: true, messageError: null }, () => {      
      this.props.store.kasimuAPIClient
        .post("/advertisements/create_ad_text_to_speech", adIA)
        .then(
          (res) => {
            if (res.results.answer_code == 5) {
              this.processFile(res.results.audio_data);
              this.reload();
            } else {
              this.setState({messageError: this.getMessager(res.results.answer_code), isGenerate: false });
            }
          },
          (error) => {
            toastManager.add(this.getMessager(6), {
              appearance: "error",
              autoDismiss: true,
            });
            this.setState({isGenerate: false });
          }
        )
        .catch((error) => {
          toastManager.add(this.getMessager(6), {
            appearance: "error",
            autoDismiss: true,
          });
          this.setState({isGenerate: false });
        });
    });
  };

  getMessagerError(value) {
    const messager = {
      0: "UNKNOWN_ERROR",
      1: this.props.store.language.label343,
      2: this.props.store.language.label894
    };
    return messager[value];
  };

  handleSave = () => {
    const { toastManager } = this.props;
    this.setState({ isSaving: true }, () => {
      this.ad.saveFormData().andThen((res, responseError) => {
        if (responseError) {
          let errorData;
          try {
            // Si es una instancia de Error, extraer y parsear su mensaje
            if (responseError instanceof Error) {
              console.error("Raw error object:", responseError);
              errorData = JSON.parse(responseError.message);
            } else if (typeof responseError === "string") {
              errorData = JSON.parse(responseError);
            } else {
              errorData = responseError;
            }
          } catch (e) {
            console.error("Error parsing responseError:", e, responseError);
            errorData = { answer_code: 0, message: "Could not parse error response" };
          }
          // Extraer el answer_code correctamente
          const answerCode = errorData?.answer_code ?? 0;

          toastManager.add(this.getMessagerError(answerCode), {
            appearance: "error",
            autoDismiss: true,
          });
          this.setState({isSaving: false });
        } else {
          toastManager.add(this.props.store.language.label344, {
            appearance: "success",
            autoDismiss: true,
          });
          this.props.store.ui.destroy();
          this.props.onSave && this.props.onSave();
        }
      });
    });
  };

  getDivUploadStyle = () => ({
    minHeight: "300px",
    border: "2px dashed #ccc",
    borderRadius: "16px",
    position: "relative",
  });

  getInputStyle = () => ({
    position: "absolute",
    margin: 0,
    padding: 0,
    width: "100%",
    height: "100%",
    outline: "none",
    opacity: 0,
  });

  getPStyle = () => ({
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "28px",
    whiteSpace: "pre-line",
    color: "#ccc",
    fontSize: "32px",
    lineHeight: "32px",
    fontWeight: "bold",
  });

  renderButtonGenerate = () => {
    const { adIA } = this.state;
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight className="mb-2">
          {this.state.isGenerate ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label885}</strong>
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={this.props.store.ui.isError || !adIA.text || !adIA.language || !this.ad.name}
              className="is-pulled-right px-3"
              onClick={this.handleGenerate}
            >
              <strong>{this.props.store.language.label886}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  renderButtonSave = () => {
    return (
      <Level isMobile>
        <LevelLeft />
        <LevelRight className="mb-2">
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label128}</strong>
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={
                !this.ad.ad_path ||
                !this.ad.name
              }
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label127}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    const { adIA } = this.state;
    let voceOptions = this.getVoceOptions();
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "90%"}
        height={this.props.store.viewMobile ? "560px" : "auto"}
      >
        <ModalHeader>
          <Text
            multiline
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
          >
            {this.props.store.language.label872}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            color="special"
            multiline
            lineBreak
          >
            {this.props.store.language.label887+": "+this.props.store.loggedInLimitations.service_limitations.limitations.limit_of_ads_generated_by_ai+"\n" +
             this.props.store.language.label888+"\n" +
             this.props.store.language.label889+"\n" +
             this.props.store.language.label890}
          </Text>
          <br />
          <div className="flex flex-col " style={{ height: "100%" }}>
            <Columns>
              <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
                <Field
                  label={this.props.store.language.label53}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  NoteSize="xs"
                  labelNote={this.props.store.language.label76}
                  weight="medium"
                >
                  <TextInput
                    backgroundColor="blackDark"
                    borderColor="black"
                    autocomplete="nope"
                    className="is-fullwidth my-1"
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    paddingInput={this.props.store.viewMobile && "20px"}
                    name="name"
                    onChange={this.handleChange}
                    //disabled={this.ad.ad_path === null ? true : false}
                  />
                </Field>
                <Field
                  label={this.props.store.language.label891}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  NoteSize="xs"
                  labelNote={this.props.store.language.label76}
                  weight="medium"
                >
                  <Textarea
                    name="text"
                    className="is-fullwidth my-1"
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    onChange={this.handleChange}
                  />
                  <div
                    className="is-row"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Text size="md" weight="medium">
                      {this.state.adIA.characters} / 350
                    </Text>
                  </div>
                </Field>
                <Field
                  label={this.props.store.language.label892}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  NoteSize="xs"
                  labelNote={this.props.store.language.label76}
                  weight="medium"
                >
                  <Select
                    isSearchable={false}
                    name={"voce"}
                    onChange={this.handleChangeSelect}
                    options={voceOptions}
                    defaultValue={
                      voceOptions[
                        voceOptions
                          .map(function (e) {
                            return e.value;
                          })
                          .indexOf(adIA.index)
                      ]
                    }
                    className="is-fullwidth my-1"
                    backgroundColor="black"
                    placeholder={this.props.store.language.label893}
                  />
                </Field>
                <br />
                {this.renderButtonGenerate()}
              </Column>

              <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
                <div
                  className="is-row"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {this.props.store.ui.showPlayer && (
                    <div className="is-row">
                      {!this.props.store.ui.isError && (
                        <Button
                          icon={
                            this.props.store.ui.isPlaying ? "pause" : "play"
                          }
                          kind="link"
                          color="primary"
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          onClick={() => this.handleTogglePlay()}
                          id="play-button"
                          space
                        />
                      )}
                      <div
                        className={`${
                          this.props.store.viewMobile ? "ml-3" : "ml-2"
                        } is-row`}
                        style={{ alignItems: "center" }}
                        maxLength={this.props.mobile ? 50 : 250}
                      >
                        {this.ad.name && (
                          <Text
                            multiline
                            weight="normal"
                            lead={this.props.store.viewMobile}
                            size={this.props.store.viewMobile ? "sm" : "xl"}
                            color={"primary"}
                          >
                            <span style={{ fontWeight: "bold" }}>
                              {this.props.store.ui.isError
                                ? this.props.store.language.label654
                                : this.ad.name}
                            </span>
                          </Text>
                        )}
                      </div>
                    </div>
                  )}
                  {!this.props.store.ui.isError &&
                    this.props.store.ui.showPlayer && (
                      <div>
                        <Text
                          size={this.props.store.viewMobile ? "md" : "sm"}
                          maxLength={this.props.mobile ? 50 : 250}
                        >
                          {this.props.store.ui.currentDuration == "NaN:NaN"
                            ? "00:00 / 00:00"
                            : this.props.store.ui.currentDuration &&
                              this.props.store.ui.currentProgressTime &&
                              this.props.store.ui.currentProgressTime +
                                " / " +
                                this.props.store.ui.currentDuration}
                        </Text>
                      </div>
                    )}
                </div>
              </Column>
            </Columns>

            <div
              id={"waveAds"}
              style={{ height: this.props.store.ui.showPlayer ? 100 : 0 }}
            />

            {this.state.messageError && (
              <>
                <br />
                <Panel color="error" className="mt-2" invert>
                  <Text
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    multiline
                  >
                    {this.state.messageError}
                  </Text>
                </Panel>
              </>
            )}
            <div className="my-1"></div>
          </div>
        </ModalContent>
        <ModalFooter>
          {this.props.store.ui.showPlayer && this.renderButtonSave()}
        </ModalFooter>
      </Modal>
    );
  }
}

AdsCreateWithAiModal.propTypes = {
  ad: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

AdsCreateWithAiModal.defaultProps = {
  ad: null,
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(AdsCreateWithAiModal));
